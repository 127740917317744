import { thunk } from 'easy-peasy';
import {
  AuthActionsAndThunks,
  DocumentType,
  IAuthThunks,
  IForgotPasswordRequestBody,
  IResendActivationRequestBody,
  IResetPasswordRequestBody,
  ISignInRequestBody,
  ISignUpAdminForm,
  ISignUpCompanyForm,
  ISignUpProjectForm,
  IStoreModel,
} from 'types';
import authAPI from 'api/auth/authAPI';
import { buildCreateDocumentsRequestBody, parseQueryParams, removeFromLS, setToLS } from 'utils';
import { LS_KEY_ACCESS_TOKEN, LS_KEY_REFRESH_TOKEN, LS_KEY_USER_PROFILE, UserRole } from 'constants/index';

export const thunks: IAuthThunks = {
  signUpCompany: thunk<AuthActionsAndThunks, ISignUpCompanyForm, unknown, IStoreModel>(
    async (actions, payload, { getStoreActions }) => {
      const storeActions = getStoreActions();
      const { documents: uploadUrls } = await authAPI.signUp({
        email: payload.email,
        name: payload.name,
        surname: payload.surname,
        password: payload.password,
        phoneNumber: payload.phoneNumber,
        accountAddress: payload.accountAddress,
        role: UserRole.Company,
        company: {
          name: payload.companyName,
          userPosition: payload.userPosition,
        },
        documents: [
          ...buildCreateDocumentsRequestBody(payload.companyLegalDocuments, DocumentType.CompanyLegal),
          ...buildCreateDocumentsRequestBody(payload.founderLegalDocuments, DocumentType.FounderLegal),
        ],
      });

      if (uploadUrls) {
        await storeActions.document.uploadDocumentInputs({
          uploadUrls,
          documents: [...payload.companyLegalDocuments, ...payload.founderLegalDocuments],
        });
      }
    },
  ),

  signUpProject: thunk<AuthActionsAndThunks, ISignUpProjectForm, unknown, IStoreModel>(async (actions, payload) => {
    await authAPI.signUp({
      email: payload.email,
      name: payload.name,
      surname: payload.surname,
      password: payload.password,
      phoneNumber: payload.phoneNumber,
      accountAddress: payload.accountAddress,
      role: UserRole.Project,
      project: {
        companyName: payload.companyName,
        userPosition: payload.userPosition,
      },
    });
  }),

  signUpAdmin: thunk<AuthActionsAndThunks, ISignUpAdminForm, unknown, IStoreModel>(
    async (actions, payload, { getStoreActions }) => {
      const storeActions = getStoreActions();
      const params = parseQueryParams();
      const { accessToken, refreshToken, ...profile } = await authAPI.signUpAdmin({
        name: payload.name,
        surname: payload.surname,
        password: payload.password,
        phoneNumber: payload.phoneNumber,
        accountAddress: payload.accountAddress,
        inviteToken: String(params.token),
      });

      setToLS(LS_KEY_ACCESS_TOKEN, accessToken);
      setToLS(LS_KEY_REFRESH_TOKEN, refreshToken);

      actions.setAuthorized(!!accessToken);
      storeActions.user.setProfile(profile);

      return profile;
    },
  ),

  signIn: thunk<AuthActionsAndThunks, ISignInRequestBody, unknown, IStoreModel>(
    async (actions, payload, { getStoreActions }) => {
      const storeActions = getStoreActions();
      const { accessToken, refreshToken, ...profile } = await authAPI.signIn(payload);

      setToLS(LS_KEY_ACCESS_TOKEN, accessToken);
      setToLS(LS_KEY_REFRESH_TOKEN, refreshToken);

      actions.setAuthorized(!!accessToken);
      storeActions.user.setProfile(profile);

      return profile;
    },
  ),

  signOut: thunk<AuthActionsAndThunks, undefined, unknown, IStoreModel>(
    async (actions, payload, { getStoreActions }) => {
      const storeActions = getStoreActions();

      removeFromLS(LS_KEY_ACCESS_TOKEN);
      removeFromLS(LS_KEY_REFRESH_TOKEN);
      removeFromLS(LS_KEY_USER_PROFILE);

      actions.setAuthorized(false);
      storeActions.user.setProfile(null);
      storeActions.project.setProject(null);
    },
  ),

  resendActivation: thunk<AuthActionsAndThunks, IResendActivationRequestBody, unknown, IStoreModel>(
    async (_, payload) => {
      await authAPI.resendActivation({ email: payload.email });
    },
  ),

  forgotPassword: thunk<AuthActionsAndThunks, IForgotPasswordRequestBody, unknown, IStoreModel>(
    async (_, payload) => await authAPI.forgotPassword(payload),
  ),

  resetPassword: thunk<AuthActionsAndThunks, IResetPasswordRequestBody, unknown, IStoreModel>(
    async (_, payload) => await authAPI.resetPassword(payload),
  ),
};
