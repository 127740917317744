import { thunk } from 'easy-peasy';
import pick from 'lodash.pick';

import {
  AdminCompaniesActionsAndThunks,
  IAdminCompaniesThunks,
  IRejectCompanyPayload,
  ICompanyResponseBody,
  IFilterRequestParams,
  IStoreModel,
} from 'types';
import adminCompaniesAPI from 'api/admin/adminCompaniesAPI';
import { CompanyStatus } from 'constants/company';
import { ErrorTypes } from 'constants/index';
import { PlatformContract } from 'blockchain';
import { TransactionReceiptStatus } from 'constants/transaction';

export const thunks: IAdminCompaniesThunks = {
  loadPendingCompanies: thunk<AdminCompaniesActionsAndThunks, IFilterRequestParams, unknown, IStoreModel>(
    async (actions, payload) => {
      actions.startPendingCompaniesLoading();
      try {
        const pendingCompanies = await adminCompaniesAPI.getPendingCompanies(
          pick(payload, ['name', 'status', 'page', 'limit', 'createdAt']),
        );
        actions.setPendingCompanies(pendingCompanies);
      } finally {
        actions.stopPendingCompaniesLoading();
      }
    },
  ),

  startCompanyReview: thunk<AdminCompaniesActionsAndThunks, string, unknown, IStoreModel>(async (actions, payload) => {
    await adminCompaniesAPI.updateCompany(payload, { status: CompanyStatus.InReview });
  }),

  approveCompany: thunk<AdminCompaniesActionsAndThunks, ICompanyResponseBody, unknown, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      const wallet = getStoreState().blockchain.wallet;

      if (!wallet.provider) {
        throw new Error(ErrorTypes.NoMetamask);
      }

      const contract = new PlatformContract(wallet.provider.getSigner());

      if (!payload.txHash) {
        const tx = await contract.registerCompany(payload);

        const receipt = await tx.wait();

        if (receipt.status === TransactionReceiptStatus.Success) {
          await adminCompaniesAPI.updateCompany(payload.id, { status: CompanyStatus.Approved, txHash: tx.hash });
        }
      } else {
        await contract.addCompanyDocuments(payload);
        await adminCompaniesAPI.updateCompany(payload.id, { status: CompanyStatus.Approved });
      }
    },
  ),

  rejectCompany: thunk<AdminCompaniesActionsAndThunks, IRejectCompanyPayload, unknown, IStoreModel>(
    async (actions, payload) => {
      await adminCompaniesAPI.updateCompany(payload.id, {
        status: CompanyStatus.Rejected,
        reason: payload.reason,
      });
    },
  ),
};
