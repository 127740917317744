import { IProjectState } from 'types';

export const initState: IProjectState = {
  projectsList: null,
  topProjectsList: null,
  topEmissionReductionsProjectsList: null,
  newProjectsList: null,
  projectById: null,
  project: null,
  projectStatements: null,
  projectPublications: null,
  projectEvents: null,
  projectMilestones: null,
  isProjectLoading: false,
  favoriteProjectsList: null,
};
