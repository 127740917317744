import { action } from 'easy-peasy';
import { IAuctionInfoResponseBody, IAuctionsListResponseBody, IAuctionActions, IAuctionState } from 'types';

export const actions: IAuctionActions = {
  setAuctionInfo: action<IAuctionState, IAuctionInfoResponseBody>((state, payload) => {
    state.auctionInfo = payload;
  }),
  setAuctionList: action<IAuctionState, IAuctionsListResponseBody>((state, payload) => {
    state.auctionList = payload;
  }),
  setAuctionBidsInfo: action<IAuctionState, any>((state, payload) => {
    state.auctionBidsInfo = payload;
  }),
  setLoading: action<IAuctionState, boolean>((state, payload) => {
    state.isLoading = payload;
  }),
  setApproved: action<IAuctionState, boolean>((state, payload) => {
    state.isApproved = payload;
  }),
  setError: action<IAuctionState, any>((state, payload) => {
    state.error = payload;
  }),
};
