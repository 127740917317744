import { Environment } from 'constants/index';
import pkg from '../../package.json';
import CarbcoinPlatformABI from './abi/CarbcoinPlatform.json';
import CarbonCreditTokenABI from './abi/CarbonCreditToken.json';
import AuctionABI from './abi/Auction.json';
import TradingABI from './abi/Trading.json';
import chainConfig from './chainConfig';

const appConfig = {
  env: process.env.NODE_ENV || Environment.Development,
  serviceUrl: process.env.REACT_APP_PLATFORM_API_URL as string,
  version: pkg.version,
  rpcEndpoint: process.env.REACT_APP_RPC_ENDPOINT,
  explorerUrl: process.env.REACT_APP_EXPLORER_URL as string,
  swapUrl: process.env.REACT_APP_SWAP_URL as string,
  bridgeUrl: process.env.REACT_APP_BRIDGE_URL as string,
  chainId: Number(process.env.REACT_APP_CHAIN_ID as string),
  chainConfig: chainConfig.testnet,
  contracts: {
    CarbcoinPlatform: {
      address: process.env.REACT_APP_CARBCOIN_PLATFORM_CONTRACT_ADDRESS as string,
      abi: CarbcoinPlatformABI,
    },
    Auction: {
      address: process.env.REACT_APP_CARBCOIN_AUCTION_CONTRACT_ADDRESS as string,
      abi: AuctionABI,
    },
    Trading: {
      address: process.env.REACT_APP_CARBCOIN_TRADING_CONTRACT_ADDRESS as string,
      abi: TradingABI,
    },
    CarbonCreditToken: {
      abi: CarbonCreditTokenABI,
    },
  },
};

// Log env
console.log('Env variables:', process.env);
console.log('App Config:', appConfig);
console.log('chainConfig:', chainConfig);

export default appConfig;
