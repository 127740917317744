import { ethers } from 'ethers';

import { LS_KEY_WALLET_STATE } from 'constants/index';
import { IWalletState } from 'types';
import { getFromLS } from 'utils';

const stateFromStorage = getFromLS(LS_KEY_WALLET_STATE, {});
const ethereum = (window as any).ethereum;

export const initState: IWalletState = {
  provider: ethereum ? new ethers.providers.Web3Provider(ethereum) : null,
  isConnected: !!stateFromStorage.walletAddress,
  chainId: stateFromStorage.chainId || null,
  walletAddress: stateFromStorage.walletAddress || null,
};
