import { useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import { version } from '../package.json';

console.log(`Build Version: ${version}`);

import { IStoreModel } from 'types';
import { useWalletVerification, useUserApproveRequired } from 'hooks';

import Pages from './routes/Pages';

const App = () => {
  const initWallet = useStoreActions<IStoreModel>((store) => store.blockchain.wallet.init);

  useEffect(() => {
    initWallet();
  }, [initWallet]);

  useWalletVerification();
  useUserApproveRequired();

  return <Pages />;
};

export default App;
