export enum Routes {
  home = '/',
  projects = '/projects',
  projectDetails = '/projects/:id',
  transactions = '/transactions',
  trading = '/trading',
  burned = '/burned',
  certificate = '/certificates/:id',
  portfolio = '/portfolio',
  signUp = '/sign-up',
  signUpCompany = '/sign-up/company',
  signUpProject = '/sign-up/project',
  signUpSuccess = '/sign-up/success',
  signUpActivate = '/activate/:token',
  signUpAdmin = '/sign-up/admin',
  walletVerification = '/wallet-verification',
  waitForApprove = '/wait-for-approve',
  signIn = '/sign-in',
  profile = '/profile',
  myProject = '/my-project',
  editMyProject = '/my-project/edit',
  addProjectStatement = '/my-project/add-statement',
  addProjectPublication = '/my-project/add-publication',
  addProjectMilestone = '/my-project/add-milestone',
  addProjectEvents = '/my-project/add-events',
  myCompany = '/my-company',
  terms = '/terms',
  privacy = '/privacy',
  forgotPassword = '/forgot-password',
  resetPassword = '/password-reset/:token',
  favoriteProjects = '/favorite-projects',
  myAuctions = '/my-auctions',
  auction = '/auction/:auctionId',
  createAuction = '/create-auction/:tokenAddr',
  unknownError = '/500',

  admin = '/admin/*',
  adminDashboard = '/admin/dashboard',
  adminTokensRequests = '/admin/tokens-requests',
  adminTokensRequest = '/admin/tokens-requests/:id',
  adminProjects = '/admin/projects',
  adminProject = '/admin/projects/:id',
  adminCompanies = '/admin/companies',
  adminCompany = '/admin/companies/:id',

  superAdmin = '/superadmin/*',
  superAdminDashboard = '/superadmin/dashboard',
  superAdminProjects = '/superadmin/projects',
  superAdminProject = '/superadmin/projects/:id',
  superAdminCompanies = '/superadmin/companies',
  superAdminCompany = '/superadmin/companies/:id',
  superAdminTokensRequests = '/superadmin/tokens-requests',
  superAdminTokensRequest = '/superadmin/tokens-requests/:id',
  superAdminUsers = '/superadmin/admin-users',
  superAdminUser = '/superadmin/admin-users/:id',
}
