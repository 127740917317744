import { action } from 'easy-peasy';

import { IAdminTokensRequestsActions, IAdminTokensRequestsState } from 'types/store/admin/tokensRequests';
import { IIssueTokens, ITokensRequestsResponseBody } from 'types/api/issue-tokens';

export const actions: IAdminTokensRequestsActions = {
  setTokensRequestsList: action<IAdminTokensRequestsState, ITokensRequestsResponseBody>((state, payload) => {
    state.tokensRequestsList = payload;
  }),
  setTokensRequestsLoading: action<IAdminTokensRequestsState, boolean>((state, payload) => {
    state.isTokensRequestsLoading = payload;
  }),
  setTokensRequest: action<IAdminTokensRequestsState, IIssueTokens | null>((state, payload) => {
    state.tokensRequest = payload;
  }),
  setIssueTokensRequest: action<IAdminTokensRequestsState, IIssueTokens | null>((state, payload) => {
    state.issueTokensRequest = payload;
  }),
};
