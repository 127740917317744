import { AuctionStore } from './../../types/store/auction';
import { initState } from './initState';
import { thunks } from './thunks';
import { actions } from './actions';

export const auction: AuctionStore = {
  ...thunks,
  ...actions,
  ...initState,
};
