export enum SectionTypes {
  All = 'All projects',
  MyPurchases = 'My Purchases',
  PurchasedProjects = 'Purchased projects',
  TopProjects = 'Top projects',
  TopEmissionReductions = 'Top Emission Reductions',
  NewProjects = 'New Projects',
}

export const sectionSizeMap: { [key: string]: number } = {
  [SectionTypes.All]: 12,
  [SectionTypes.TopProjects]: 3,
  [SectionTypes.MyPurchases]: 4,
  [SectionTypes.PurchasedProjects]: 4,
  [SectionTypes.TopProjects]: 3,
  [SectionTypes.TopEmissionReductions]: 4,
  [SectionTypes.NewProjects]: 4,
};

interface Params {
  limit?: number;
  sortBy?: string;
  sortOrder?: string;
}

export const paramsMap: { [key: string]: Params } = {
  [SectionTypes.All]: { limit: 8 },
  [SectionTypes.TopProjects]: { limit: 3, sortBy: 'score' },
  [SectionTypes.TopEmissionReductions]: { limit: 6, sortBy: 'emissionReductions' },
  [SectionTypes.NewProjects]: { limit: 6, sortBy: 'createdAt' },
};
