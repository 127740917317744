import { AdminCompaniesStore } from '../../../types/store/admin/companies';
import { initState } from './initState';
import { actions } from './actions';
import { thunks } from './thunks';

export const companies: AdminCompaniesStore = {
  ...initState,
  ...actions,
  ...thunks,
};
