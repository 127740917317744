import { ethers } from 'ethers';
import { JsonRpcSigner } from '@ethersproject/providers';
import { TransactionResponse } from '@ethersproject/abstract-provider';

import appConfig from 'config/appConfig';
import { Provider } from '../Provider';

export class TokenContract {
  publicContract: ethers.Contract;
  contract?: ethers.Contract;

  constructor(tokenAddr: string, signer?: JsonRpcSigner) {
    this.publicContract = new ethers.Contract(
      tokenAddr,
      appConfig.contracts.CarbonCreditToken.abi,
      Provider.rpcProvider,
    );
    this.contract = signer && new ethers.Contract(tokenAddr, appConfig.contracts.CarbonCreditToken.abi, signer);
  }

  async getTotalSupply(): Promise<string> {
    const totalSupply = await this.publicContract.totalSupply();
    return ethers.utils.formatEther(totalSupply);
  }

  async getTotalBurned(): Promise<string> {
    const totalBurned = await this.publicContract.totalBurned();
    return ethers.utils.formatEther(totalBurned);
  }

  async allowance(ownerAddress: string, allowanceAddress: string): Promise<string> {
    const allowanceBalance = await this.publicContract.allowance(ownerAddress, allowanceAddress);
    return ethers.utils.formatEther(allowanceBalance);
  }

  async balanceOf(accountAddress: string): Promise<string> {
    const balance = await this.publicContract.balanceOf(accountAddress);
    return ethers.utils.formatEther(balance);
  }

  async approve(accountAddress: string, amountToApprove: string): Promise<TransactionResponse> {
    return this.contract?.approve(
      appConfig.contracts.Auction.address,
      ethers.utils.parseEther(amountToApprove).toString(),
    );
  }
  // @todo refactor to approve method
  async approveTrading(amountToApprove: string): Promise<TransactionResponse> {
    return this.contract?.approve(
      appConfig.contracts.Trading.address,
      ethers.utils.parseEther(amountToApprove).toString(),
    );
  }
}
