import { ethers } from 'ethers';
import { JsonRpcSigner } from '@ethersproject/providers';
import { TransactionResponse } from '@ethersproject/abstract-provider';

import appConfig from 'config/appConfig';
import { getDocumentHashes } from 'utils';
import { DocumentType, ICompanyResponseBody, IProjectDetailsResponseBody } from 'types';

export class PlatformContract {
  contract: ethers.Contract;

  constructor(signer: JsonRpcSigner) {
    this.contract = new ethers.Contract(
      appConfig.contracts.CarbcoinPlatform.address,
      appConfig.contracts.CarbcoinPlatform.abi,
      signer,
    );
  }

  async registerProject(project: IProjectDetailsResponseBody): Promise<TransactionResponse> {
    const projectDocuments = JSON.stringify(
      await getDocumentHashes(project.documents, [DocumentType.AuditCompanyLegal]),
    );
    console.log('registering project', project);
    return this.contract.registerProject(
      project.user.accountAddress,
      projectDocuments,
      project.tokenName,
      project.tokenTicker,
    );
  }

  // TODO: Call smart contract
  async addProjectDocuments(project: IProjectDetailsResponseBody): Promise<string> {
    const projectDocuments = JSON.stringify(
      await getDocumentHashes(project.documents, [DocumentType.AuditCompanyLegal]),
    );
    return projectDocuments;
  }

  async registerCompany(company: ICompanyResponseBody): Promise<TransactionResponse> {
    const companyDocuments = JSON.stringify(await getDocumentHashes(company.documents));
    return this.contract.registerConsumer(company.user.accountAddress, companyDocuments);
  }

  // TODO: Call smart contract
  async addCompanyDocuments(company: ICompanyResponseBody): Promise<string> {
    const companyDocuments = JSON.stringify(await getDocumentHashes(company.documents));
    return companyDocuments;
  }

  async hasSuperAdminRole(accountAddress: string) {
    const adminRoleAddress = await this.contract.DEFAULT_ADMIN_ROLE();

    return this.contract.hasRole(adminRoleAddress, accountAddress);
  }

  async hasAdminRole(accountAddress: string) {
    const adminRoleAddress = await this.contract.PLATFORM_ADMIN_ROLE();

    return this.contract.hasRole(adminRoleAddress, accountAddress);
  }

  async registerAdmin(accountAddress: string): Promise<string> {
    return this.contract.registerPlatformAdmin(accountAddress);
  }

  async mint(accountAddress: string, amount: number): Promise<TransactionResponse> {
    return this.contract.mint(accountAddress, ethers.utils.parseEther(amount.toString()).toString());
  }

  async burnTokens(tokenAddr: string, amount: number): Promise<TransactionResponse> {
    return this.contract.burn(tokenAddr, ethers.utils.parseEther(String(amount)).toString());
  }

  async revokeAdminRole(accountAddress: string) {
    const adminRoleAddress = await this.contract.PLATFORM_ADMIN_ROLE();

    return this.contract.revokeRole(adminRoleAddress, accountAddress);
  }
}
