import { useState, useEffect, useCallback } from 'react';

const useFetch = <T extends unknown>(callback: () => Promise<T> | null, dependencies: Array<unknown> = []) => {
  const [response, setResponse] = useState<T | null>(null);
  const [error, setError] = useState<unknown>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [retryTrigger, setRetryTrigger] = useState<boolean>(false);

  const retry = useCallback(() => {
    setRetryTrigger(!retryTrigger);
  }, [retryTrigger]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const doFetch = async () => {
      setLoading(true);
      try {
        const res = await callback();
        if (!signal.aborted) {
          setResponse(res);
        }
      } catch (error) {
        if (!signal.aborted) {
          setError(error);
        }
      } finally {
        if (!signal.aborted) {
          setLoading(false);
        }
      }
    };

    doFetch();

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retryTrigger, ...dependencies]);

  return { response, error, loading, retry };
};

export default useFetch;
