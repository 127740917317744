import { Icons, ProjectTypes } from 'types';
import energyDemandCoverImg from 'resources/images/bg/project-type/Energy_demand_bg.jpg';
import forestryCoverImg from 'resources/images/bg/project-type/Foresty_bg.jpg';
import energyDistributionCoverImg from 'resources/images/bg/project-type/EnergyDistribution_bg.jpg';
import energyIndustriesCoverImg from 'resources/images/bg/project-type/EnergyIndustries_bg.jpg';
import chemicalIndustryCoverImg from 'resources/images/bg/project-type/ChemicalIndustry_bg.jpg';
import fugitiveEmissionsCoverImg from 'resources/images/bg/project-type/FugitiveEmissions_bg.jpg';
import livestockCoverImg from 'resources/images/bg/project-type/Livestock_bg.jpg';
import manufacturingCoverImg from 'resources/images/bg/project-type/ManufacturingIndustries_bg.jpg';
import metalProductionCoverImg from 'resources/images/bg/project-type/MetalProduction_bg.jpg';
import miningCoverImg from 'resources/images/bg/project-type/Mining_bg.jpg';
import transportCoverImg from 'resources/images/bg/project-type/Transport_bg.jpg';
import wasteHandlingAndDisposalCoverImg from 'resources/images/bg/project-type/WasteHandlingAndDisposal_bg.jpg';

export enum ProjectStatus {
  Created = 'created',
  Edited = 'edited',
  InReview = 'in-review',
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
  ApproveInProgress = 'approve-in-progress',
}

export enum ProjectStandards {
  Verra = 'Verra',
  ClimateActionReserve = 'Climate Action Reserve',
  CDM = 'CDM',
  GoldStandard = 'Gold Standard',
  GlobalCarbonCouncil = 'Global Carbon Council',
}

export const projectTypeMap: { [key in ProjectTypes]: Icons } = {
  [ProjectTypes.EnergyDemand]: 'energy',
  [ProjectTypes.Forestry]: 'forestry',
  [ProjectTypes.EnergyDistribution]: 'energyDistribution',
  [ProjectTypes.EnergyIndustries]: 'energyIndustries',
  [ProjectTypes.ChemicalIndustry]: 'chemicalIndustry',
  [ProjectTypes.FugitiveEmissions]: 'fugitiveEmissions',
  [ProjectTypes.Livestock]: 'livestock',
  [ProjectTypes.ManufacturingIndustries]: 'manufacturing',
  [ProjectTypes.MetalProduction]: 'metalProduction',
  [ProjectTypes.Mining]: 'mining',
  [ProjectTypes.Transport]: 'transport',
  [ProjectTypes.WasteHandlingAndDisposal]: 'wasteHandling',
};

export const projectTypeBackgroundMap: { [key in ProjectTypes]: any } = {
  [ProjectTypes.EnergyDemand]: energyDemandCoverImg,
  [ProjectTypes.Forestry]: forestryCoverImg,
  [ProjectTypes.EnergyDistribution]: energyDistributionCoverImg,
  [ProjectTypes.EnergyIndustries]: energyIndustriesCoverImg,
  [ProjectTypes.ChemicalIndustry]: chemicalIndustryCoverImg,
  [ProjectTypes.FugitiveEmissions]: fugitiveEmissionsCoverImg,
  [ProjectTypes.Livestock]: livestockCoverImg,
  [ProjectTypes.ManufacturingIndustries]: manufacturingCoverImg,
  [ProjectTypes.MetalProduction]: metalProductionCoverImg,
  [ProjectTypes.Mining]: miningCoverImg,
  [ProjectTypes.Transport]: transportCoverImg,
  [ProjectTypes.WasteHandlingAndDisposal]: wasteHandlingAndDisposalCoverImg,
};
