import { AdminTokensRequestsStore } from 'types/store/admin/tokensRequests';
import { initState } from './initState';
import { actions } from './actions';
import { thunks } from './thunks';

export const tokensRequests: AdminTokensRequestsStore = {
  ...initState,
  ...actions,
  ...thunks,
};
