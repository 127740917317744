export enum DocumentType {
  CoverImage = 'CoverImage',
  VcsPipeline = 'VcsPipeline',
  VcsRegistration = 'VcsRegistration',
  VcsIssuance = 'VcsIssuance',
  VcsOther = 'VcsOther',
  CompanyLegal = 'CompanyLegal',
  FounderLegal = 'FounderLegal',
  AuditCompanyLegal = 'AuditCompanyLegal',
  Other = 'Other',
}

export enum DocumentRelatedEntity {
  Project = 'Project',
  Company = 'Company',
}

export interface ICreateDocumentRequestBody {
  id?: number;
  fileName: string;
  fileType: string;
  fileSizeBytes: number;
  documentType?: DocumentType;
}

export interface IDocumentResponseBody {
  id: number;
  fileName: string;
  fileType: string;
  fileSizeBytes: number;
  documentType: DocumentType;
  relatedEntity: DocumentRelatedEntity;
  relatedEntityId: number;
  url: string;
  createdAt: string;
}

export interface IDocumentHash {
  alg: string;
  name: string;
  hash: string;
  ts: number;
}
