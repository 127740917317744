export type Icons =
  | 'phone'
  | 'key'
  | 'company'
  | 'metamask'
  | 'agency'
  | 'mapPoint'
  | 'status'
  | 'filter'
  | 'star'
  | 'starFilled'
  | 'energy'
  | 'forestry'
  | 'portfolio'
  | 'projects'
  | 'wallet'
  | 'search'
  | 'project'
  | 'dashboard'
  | 'requests'
  | 'avatar'
  | 'profile'
  | 'copy'
  | 'approved'
  | 'created'
  | 'rejected'
  | 'pending'
  | 'document'
  | 'download'
  | 'website'
  | 'address'
  | 'arrowRight'
  | 'event'
  | 'mail'
  | 'factory'
  | 'planet'
  | 'arrowLeft'
  | 'favourites'
  | 'greenPending'
  | 'link'
  | 'edit'
  | 'burned'
  | 'mask'
  | 'time'
  | 'auction'
  | 'wasteHandling'
  | 'transport'
  | 'mining'
  | 'metalProduction'
  | 'manufacturing'
  | 'livestock'
  | 'fugitiveEmissions'
  | 'chemicalIndustry'
  | 'energyIndustries'
  | 'energyDistribution'
  | 'bridge'
  | 'dex'
  | 'explorer';

export enum IconType {
  icon = 'icon',
  img = 'img',
}
