import { action } from 'easy-peasy';

import { IAdminUsersActions, IAdminUsersResponseBody, IAdminUsersState } from 'types';

export const actions: IAdminUsersActions = {
  setUsers: action<IAdminUsersState, IAdminUsersResponseBody>((state, payload) => {
    state.users = payload;
  }),
  startUsersLoading: action<IAdminUsersState>((state) => {
    state.isUsersLoading = true;
  }),
  stopUsersLoading: action<IAdminUsersState>((state) => {
    state.isUsersLoading = false;
  }),
};
