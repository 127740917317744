import { IPaginationResponseBody } from './common';

export type TradeItemType = 'bid' | 'ask';

export enum TradeTypes {
  Ask = 'ask',
  Bid = 'bid',
}

export interface ITradeItem {
  id: number;
  projectId: number;
  type: TradeItemType;
  price: number;
  amount: number;
  totalPrice: number; // dynamic value, calculated on UI
  blockchainTradeId?: string; // bid/ask id from blockchain todo - add to API & db
  address?: string; // token address
  txHash?: string;
  offerId: number;
}

export interface ITrading {
  amount: number;
  createdAt: string;
  id: number;
  project: {
    id: number;
    name: string;
    tokenTicker: string;
  };
  tokenPrice: number;
  type: TradeItemType;
  address?: string; // token address
  offerId: number;
  userId: number;
}

export type ITradeItemResponseBody = IPaginationResponseBody<ITrading>;
