import API from '../API';

import appConfig from 'config/appConfig';
import { IAddressVerificationResponseBody, IVerifyAddressRequestBody } from 'types';

const addressVerificationAPI = {
  getVerificationMessage(): Promise<IAddressVerificationResponseBody> {
    return API.get<IAddressVerificationResponseBody>(`${appConfig.serviceUrl}/api/users/verification-message`).then(
      (response) => response.data,
    );
  },

  verifyAddress(body: IVerifyAddressRequestBody): Promise<IAddressVerificationResponseBody> {
    return API.post<IVerifyAddressRequestBody, IAddressVerificationResponseBody>(
      `${appConfig.serviceUrl}/api/users/verify-address`,
      body,
    ).then((response) => response.data);
  },
};

export default addressVerificationAPI;
