import appConfig from 'config/appConfig';
import { IChangePasswordRequestBody, IUpdateProfileRequestBody, IUserProfileResponseBody } from 'types';
import API from '../API';

const profileAPI = {
  getMyProfile(): Promise<IUserProfileResponseBody> {
    return API.get<IUserProfileResponseBody>(`${appConfig.serviceUrl}/api/users/me`).then((response) => response.data);
  },

  updateMyProfile(body: IUpdateProfileRequestBody): Promise<IUserProfileResponseBody> {
    return API.patch<IUpdateProfileRequestBody, IUserProfileResponseBody>(
      `${appConfig.serviceUrl}/api/users/me`,
      body,
    ).then((response) => response.data);
  },

  changePassword(body: IChangePasswordRequestBody): Promise<unknown> {
    return API.patch<IChangePasswordRequestBody, unknown>(
      `${appConfig.serviceUrl}/api/users/change-password`,
      body,
    ).then((response) => response.data);
  },
};

export default profileAPI;
