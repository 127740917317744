import appConfig from 'config/appConfig';
import { IPaginationResponse } from 'types';
import { IAuctionInfoResponseBody, IAuctionsListResponseBody } from 'types/api/auction';
import API from '../API';

const auctionAPI = {
  auctionInfoAPI(id: string): Promise<IAuctionInfoResponseBody> {
    return API.get<IAuctionInfoResponseBody>(`${appConfig.serviceUrl}/api/auctions/${id}`).then(
      (response) => response.data,
    );
  },
  auctionBidsInfoAPI(params: {
    walletAddress: string;
    page: number;
    pageSize: number;
  }): Promise<IPaginationResponse<IAuctionInfoResponseBody>> {
    return API.get<IPaginationResponse<IAuctionInfoResponseBody>>(
      `${appConfig.serviceUrl}/api/auctions/bids/${params.walletAddress}?page=${params.page}&limit=${params.pageSize}`,
    ).then((response) => response.data);
  },
  auctionsListAPI(walletAddress: string): Promise<IAuctionsListResponseBody> {
    return API.get<IAuctionsListResponseBody>(`${appConfig.serviceUrl}/api/auctions/my/${walletAddress}`).then(
      (response) => response.data,
    );
  },
};
export default auctionAPI;
