import { IAccessTokenPayload } from '../types/api/auth';

export const getAccessTokenPayload = (accessToken: string): IAccessTokenPayload | null => {
  try {
    return JSON.parse(atob(accessToken.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const checkAccessTokenExpired = (accessToken: string): boolean => {
  const accessTokenPayload = getAccessTokenPayload(accessToken);
  return accessTokenPayload ? accessTokenPayload.exp <= Date.now() : false;
};
