import { UserStore } from '../../types/store/user';
import { initState } from './initState';
import { actions } from './actions';
import { thunks } from './thunks';

export const user: UserStore = {
  ...initState,
  ...actions,
  ...thunks,
};
