import { action } from 'easy-peasy';

import { LS_KEY_WALLET_STATE } from 'constants/index';
import { IWalletActions, IWalletState } from 'types';
import { getFromLS, removeFromLS, setToLS } from 'utils';

export const actions: IWalletActions = {
  setWalletAddress: action<IWalletState, string>((state, payload) => {
    state.walletAddress = payload;

    setToLS(LS_KEY_WALLET_STATE, {
      ...getFromLS(LS_KEY_WALLET_STATE, {}),
      walletAddress: payload,
    });
  }),
  setConnected: action<IWalletState, boolean>((state, payload) => {
    state.isConnected = payload;
  }),
  setChainId: action<IWalletState, number | null>((state, payload) => {
    state.chainId = payload;
    setToLS(LS_KEY_WALLET_STATE, {
      ...getFromLS(LS_KEY_WALLET_STATE, {}),
      chainId: payload,
    });
  }),
  disconnect: action<IWalletState>((state) => {
    state.isConnected = false;
    state.walletAddress = null;
    removeFromLS(LS_KEY_WALLET_STATE);
  }),
};
