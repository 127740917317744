import { thunk } from 'easy-peasy';
import pick from 'lodash.pick';

import { ErrorTypes, ProjectStatus } from 'constants/index';
import {
  AdminProjectsActionsAndThunks,
  IAdminProjectsThunks,
  IApproveProjectPayload,
  IFilterRequestParams,
  IRejectProjectPayload,
  IStoreModel,
} from 'types';
import adminProjectsAPI from 'api/admin/adminProjectsAPI';
import { PlatformContract } from 'blockchain';
import { TransactionReceiptStatus } from 'constants/transaction';

export const thunks: IAdminProjectsThunks = {
  loadPendingProjects: thunk<AdminProjectsActionsAndThunks, IFilterRequestParams, unknown, IStoreModel>(
    async (actions, payload) => {
      actions.startPendingProjectsLoading();
      try {
        const pendingProjects = await adminProjectsAPI.getPendingProjects(
          pick(payload, ['name', 'status', 'page', 'limit', 'createdAt']),
        );
        actions.setPendingProjects(pendingProjects);
      } finally {
        actions.stopPendingProjectsLoading();
      }
    },
  ),

  loadProjectById: thunk<AdminProjectsActionsAndThunks, string | number, unknown, IStoreModel>(
    async (actions, payload) => {
      const project = await adminProjectsAPI.getProjectById(payload);
      actions.setProject(project);
    },
  ),

  startProjectReview: thunk<AdminProjectsActionsAndThunks, string, unknown, IStoreModel>(async (actions, payload) => {
    await adminProjectsAPI.updateProject(payload, { status: ProjectStatus.InReview });
  }),

  approveProject: thunk<AdminProjectsActionsAndThunks, IApproveProjectPayload, unknown, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      const wallet = getStoreState().blockchain.wallet;

      // Create Project Token
      if (!payload.project.tokenAddr && payload.isAuditApproved) {
        if (!wallet.provider) {
          console.log('No provider');
          throw new Error(ErrorTypes.NoMetamask);
        }

        if (!wallet.isConnected) {
          console.log('Not connected');
          throw new Error(ErrorTypes.WalletNotConnected);
        }

        const contract = new PlatformContract(wallet.provider.getSigner());
        console.log('Registering project');
        const tx = await contract.registerProject(payload.project);

        const receipt = await tx.wait();

        if (receipt.status === TransactionReceiptStatus.Success) {
          await adminProjectsAPI.updateProject(payload.project.id, {
            status: ProjectStatus.Approved,
            txHash: tx.hash,
          });
        }
      } else {
        await adminProjectsAPI.updateProject(payload.project.id, {
          status: ProjectStatus.Approved,
        });
      }
    },
  ),

  rejectProject: thunk<AdminProjectsActionsAndThunks, IRejectProjectPayload, unknown, IStoreModel>(
    async (actions, payload) => {
      await adminProjectsAPI.updateProject(payload.id, {
        status: ProjectStatus.Rejected,
        reason: payload.reason,
      });
    },
  ),
};
