import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { useStoreState } from 'easy-peasy';

import { Routes } from 'constants/routes';
import { IStoreModel } from 'types';

interface IHookOption {
  isWalletRequired?: boolean;
  isRedirect?: boolean;
  redirectRoute?: Routes;
}

interface IHookResponse {
  hasAccess?: boolean;
  walletAddress: string | null;
}

const useWallet = ({
  isRedirect = true,
  isWalletRequired = true,
  redirectRoute = Routes.home,
}: IHookOption = {}): IHookResponse => {
  const walletAddress = useStoreState<IStoreModel, string | null>((store) => store.blockchain.wallet.walletAddress);
  const history = useHistory();
  const hasAccess = !isWalletRequired || !!walletAddress;

  if (!hasAccess && isRedirect) {
    history.replace(redirectRoute);
  }

  return useMemo(() => ({ hasAccess, walletAddress }), [hasAccess, walletAddress]);
};

export default useWallet;
