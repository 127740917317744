import { ethers } from 'ethers';

import {
  DocumentType,
  IBurnedTokensResponseBody,
  ICreateDocumentRequestBody,
  IDocumentHash,
  IDocumentResponseBody,
  IUploadFile,
} from 'types';

export const getFileBase64 = (file: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      reader.result ? resolve(reader.result.toString()) : resolve('');
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const buildCreateDocumentsRequestBody = (
  files: Array<IUploadFile>,
  documentType?: DocumentType,
): Array<ICreateDocumentRequestBody> => {
  return files.map<ICreateDocumentRequestBody>((file) => {
    return {
      id: file.id,
      fileName: file.originFileObj?.name || 'unknown',
      fileType: file.type || 'unknown',
      fileSizeBytes: file.size || 0,
      documentType,
    };
  });
};

export const buildUploadFile = (document: IDocumentResponseBody): IUploadFile => {
  return {
    id: document.id,
    url: document.url,
    name: document.fileName,
    type: document.fileType,
  } as IUploadFile;
};

export const buildUploadFilesByDocumentType = (
  documents: Array<IDocumentResponseBody> = [],
  documentType: DocumentType,
): Array<IUploadFile> => {
  return documents.filter((document) => document.documentType === documentType).map(buildUploadFile);
};

export const getDocumentHash = async (fileName: string, url: string): Promise<IDocumentHash> => {
  const fileBlob = await (await fetch(url)).blob();
  const fileDataHex = Buffer.from(await fileBlob.text()).toString('hex');
  return {
    alg: 'SHA2-256',
    name: fileName,
    hash: ethers.utils.sha256(`0x${fileDataHex}`),
    ts: Date.now(),
  };
};

export const getDocumentHashes = async (
  documents: Array<IDocumentResponseBody> = [],
  documentTypes: Array<DocumentType> = Object.values(DocumentType),
): Promise<Array<IDocumentHash>> => {
  const hashes = [];

  for (const doc of documents) {
    if (documentTypes.includes(doc.documentType)) {
      hashes.push(await getDocumentHash(doc.fileName, doc.url));
    }
  }

  return hashes;
};

export const getCertificateFileName = (burnTokens: IBurnedTokensResponseBody) => {
  return `Carbon_Coin_Certificate_#${burnTokens.certId}_${burnTokens.amount}_${burnTokens.project.tokenTicker}.pdf`;
};
