import moment from 'moment';
import i18n from './../i18n';

moment.locale(i18n.language);

export const formatDate = (date: string, formatting: string) => {
  return moment(date).format(formatting);
};

export const formatDateDay = (date: string) => {
  return formatDate(date, 'DD/MM/YYYY');
};

export const formatDateDayLocale = (date: string) => {
  return formatDate(date, 'DD MMM, yyyy');
};

export const formatDateTime = (date: string) => {
  return formatDate(date, 'DD MMM, yyyy, H:mm');
};
