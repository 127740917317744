import { AdminUsersStore } from 'types';
import { initState } from './initState';
import { actions } from './actions';
import { thunks } from './thunks';

export const users: AdminUsersStore = {
  ...initState,
  ...actions,
  ...thunks,
};
