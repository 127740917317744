import { action } from 'easy-peasy';

import { IAdminCompaniesState } from '../../../types/store/admin/companies';
import { IAdminCompaniesActions } from '../../../types/store/admin/companies';
import { IPendingCompaniesResponseBody } from '../../../types/api/admin/companies';

export const actions: IAdminCompaniesActions = {
  setPendingCompanies: action<IAdminCompaniesState, IPendingCompaniesResponseBody>((state, payload) => {
    state.pendingCompanies = payload;
  }),
  startPendingCompaniesLoading: action<IAdminCompaniesState>((state) => {
    state.isPendingCompaniesLoading = true;
  }),
  stopPendingCompaniesLoading: action<IAdminCompaniesState>((state) => {
    state.isPendingCompaniesLoading = false;
  }),
};
