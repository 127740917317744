import { ReactNode } from 'react';
import { UploadFile } from 'antd/es/upload/interface';
import { Icons } from './icons';

export interface IBasicInputProps<V> {
  name?: string;
  label?: ReactNode;
  placeholder?: string;
  error?: any;
  onChange?: (value?: V) => void;
  onBlur?: () => void;
  icon?: Icons;
}

export interface IUploadFile extends UploadFile {
  id?: number;
}

export enum DocumentUploadType {
  File = 'File',
  Image = 'Image',
}

export interface ISelectInputOption {
  value: string;
  content?: ReactNode;
}

export interface ISelectOptionWithData<T> extends ISelectInputOption {
  data?: T;
}

export interface ISearchSelectLocationData {
  latitude: number;
  longitude: number;
  country: string;
  city: string;
  state: string;
  zipcode: string;
  streetName: string;
  streetNumber: string;
  countryCode: string;
  county: string;
}

export interface ISearchProjectCoords {
  latitude: number;
  longitude: number;
}

export interface ISearchLocationResponse {
  places: Array<ISearchSelectLocationData>;
}
