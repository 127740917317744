import { DocumentStore } from '../../types/store/document';
import { initState } from './initState';
import { actions } from './actions';
import { thunks } from './thunks';

export const document: DocumentStore = {
  ...initState,
  ...actions,
  ...thunks,
};
