import API from '../API';
import appConfig from 'config/appConfig';
import {
  IActivateRequestBody,
  IActivateResponseBody,
  IInviteAdminRequestBody,
  IRefreshTokensRequestBody,
  IRefreshTokensResponseBody,
  ISignInRequestBody,
  ISignInResponseBody,
  ISignUpAdminRequestBody,
  ISignUpRequestBody,
  ISignUpResponseBody,
  IResendActivationRequestBody,
  IMessageResponse,
  IForgotPasswordRequestBody,
  IResetPasswordRequestBody,
} from 'types';

const authAPI = {
  signIn(body: ISignInRequestBody): Promise<ISignInResponseBody> {
    return API.post<ISignInRequestBody, ISignInResponseBody>(`${appConfig.serviceUrl}/api/auth/sign-in`, body, {
      isPublic: true,
    }).then((response) => response.data);
  },

  signUp(body: ISignUpRequestBody): Promise<ISignUpResponseBody> {
    return API.post<ISignUpRequestBody, ISignUpResponseBody>(`${appConfig.serviceUrl}/api/auth/sign-up`, body, {
      isPublic: true,
    }).then((response) => response.data);
  },

  inviteAdmin(body: IInviteAdminRequestBody): Promise<void> {
    return API.post<IInviteAdminRequestBody, void>(`${appConfig.serviceUrl}/api/auth/admin/invite`, body).then(
      (response) => response.data,
    );
  },

  signUpAdmin(body: ISignUpAdminRequestBody): Promise<ISignInResponseBody> {
    return API.post<ISignUpAdminRequestBody, ISignInResponseBody>(
      `${appConfig.serviceUrl}/api/auth/admin/sign-up`,
      body,
      {
        isPublic: true,
      },
    ).then((response) => response.data);
  },

  activate(body: IActivateRequestBody): Promise<IActivateResponseBody> {
    return API.post<IActivateRequestBody, IActivateResponseBody>(`${appConfig.serviceUrl}/api/auth/activate`, body, {
      isPublic: true,
    }).then((response) => response.data);
  },

  refreshTokens(body: IRefreshTokensRequestBody): Promise<IRefreshTokensResponseBody> {
    return API.post<IRefreshTokensRequestBody, IRefreshTokensResponseBody>(
      `${appConfig.serviceUrl}/api/auth/refresh-tokens`,
      body,
      {
        isPublic: true,
      },
    ).then((response) => response.data);
  },

  resendActivation(body: IResendActivationRequestBody): Promise<IMessageResponse> {
    return API.post<IResendActivationRequestBody, IMessageResponse>(
      `${appConfig.serviceUrl}/api/auth/resend-activation`,
      body,
      {
        isPublic: true,
      },
    ).then((response) => response.data);
  },

  forgotPassword(body: IForgotPasswordRequestBody): Promise<IMessageResponse> {
    return API.post<IForgotPasswordRequestBody, IMessageResponse>(`${appConfig.serviceUrl}/api/password/forgot`, body, {
      isPublic: true,
    }).then((response) => response.data);
  },

  resetPassword(body: IResetPasswordRequestBody): Promise<IMessageResponse> {
    return API.patch<IResetPasswordRequestBody, IMessageResponse>(`${appConfig.serviceUrl}/api/password/reset`, body, {
      isPublic: true,
    }).then((response) => response.data);
  },
};

export default authAPI;
