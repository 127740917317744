import isObject from 'lodash.isobject';
import { SchemaOf } from 'yup';
import { CastOptions } from 'yup/lib/schema';

const emptyValuesToEmptyStrings = (obj: any) =>
  Object.keys(obj).reduce(
    (acc, key) => {
      let value = obj[key];
      if (value === undefined || value === null) value = '';

      if (value instanceof Date) {
        value = new Date(value);
        return { ...acc, [key]: value };
      }

      if (isObject(value) || Array.isArray(value)) {
        value = emptyValuesToEmptyStrings(value);
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return Array.isArray(obj) ? [...acc, value] : { ...acc, [key]: value };
    },
    Array.isArray(obj) ? [] : {},
  );

export const buildInitialValues = <T>(schema: SchemaOf<T>, existingDoc = {}, options?: CastOptions) =>
  emptyValuesToEmptyStrings(schema.cast(existingDoc, options));
