import { useState, useCallback } from 'react';

const useFetchCallback = <T extends unknown>(
  callback: (...args: any[]) => Promise<T> | null,
  dependencies: Array<unknown> = [],
) => {
  const [response, setResponse] = useState<T | null>(null);
  const [error, setError] = useState<unknown>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchCallback = useCallback(
    async (...args) => {
      const abortController = new AbortController();
      const signal = abortController.signal;

      const doFetch = async () => {
        setLoading(true);
        try {
          const res = await callback(...args);
          if (!signal.aborted) {
            setResponse(res);
          }
        } catch (error) {
          if (!signal.aborted) {
            setError(error);
          }
        } finally {
          if (!signal.aborted) {
            setLoading(false);
          }
        }
      };
      doFetch();
      return () => {
        abortController.abort();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback, dependencies],
  );

  return { response, error, loading, fetchCallback };
};

export default useFetchCallback;
