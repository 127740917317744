import * as Yup from 'yup';

export const documentsValidation = Yup.array()
  .of(
    Yup.object()
      .shape({
        size: Yup.number().max(100000000, 'validation.invalidFileSize'),
      })
      .unknown(),
  )
  .min(1, 'validation.required')
  .required('validation.required');

export const documentsValidationOptional = Yup.array()
  .of(
    Yup.object()
      .shape({
        size: Yup.number().max(100000000, 'validation.invalidFileSize'),
      })
      .unknown(),
  )
  .optional();

export const phoneValidation = Yup.string()
  .matches(
    /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm,
    'validation.invalidPhoneNumber',
  )
  .min(10, 'validation.invalidPhoneNumber')
  .max(13, 'validation.invalidPhoneNumber');

export const websiteValidation = Yup.string().matches(
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g,
  'validation.invalidUrl',
);

export const passwordValidation = Yup.string().matches(
  /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
  'validation.invalidPassword',
);

export const projectNameValidation = Yup.string().matches(/(^[A-Za-z0-9 ._-]*$)/g, 'validation.projectName');

//change validation if token name can have underscores or hyphen
export const tokenNameValidation = Yup.string().matches(/(^[A-Za-z ]*$)/g, 'validation.tokenNameCharacters');
export const tokenTickerValidation = Yup.string().matches(/(^[A-Za-z]*$)/g, 'validation.tokenTickerCharacters');
