import { action } from 'easy-peasy';
import { IUserActions, IUserState } from '../../types';
import { IUserProfileResponseBody } from '../../types';
import { setToLS } from '../../utils';
import { LS_KEY_USER_PROFILE } from '../../constants';

export const actions: IUserActions = {
  setProfile: action<IUserState, IUserProfileResponseBody>((state, payload) => {
    state.profile = payload;
    setToLS(LS_KEY_USER_PROFILE, payload);
  }),

  startProfileLoading: action<IUserState>((state) => {
    state.isProfileLoading = true;
  }),

  stopProfileLoading: action<IUserState>((state) => {
    state.isProfileLoading = false;
  }),
};
