import { useState, useCallback } from 'react';

interface IHookOptions {
  isOpenByDefault?: boolean;
}

const useModal = ({ isOpenByDefault = false }: IHookOptions = {}) => {
  const [isOpen, setIsOpen] = useState<boolean>(isOpenByDefault);
  const [loading, setLoading] = useState<boolean>(false);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
    setLoading(false);
  }, []);

  return { isOpen, open, close, loading, setLoading };
};

export default useModal;
