import { action } from 'easy-peasy';

import { IAdminProjectsActions, IAdminProjectsState } from '../../../types/store/admin/projects';
import { IPendingProjectsResponseBody } from '../../../types/api/admin/projects';
import { IProjectDetailsResponseBody } from '../../../types/api/project';

export const actions: IAdminProjectsActions = {
  setPendingProjects: action<IAdminProjectsState, IPendingProjectsResponseBody>((state, payload) => {
    state.pendingProjects = payload;
  }),
  startPendingProjectsLoading: action<IAdminProjectsState>((state) => {
    state.isPendingProjectsLoading = true;
  }),
  stopPendingProjectsLoading: action<IAdminProjectsState>((state) => {
    state.isPendingProjectsLoading = false;
  }),
  setProject: action<IAdminProjectsState, IProjectDetailsResponseBody | null>((state, payload) => {
    state.project = payload;
  }),
};
