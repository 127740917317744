export enum FilterTypes {
  Location = 'Location',
  ProjectType = 'Project type',
  Standard = 'Standard',
  Status = 'Status',
  Methodology = 'Methodology',
}

export const filterKeyToQueryParamMap: Record<string, string> = {
  [FilterTypes.Location]: 'country',
  [FilterTypes.ProjectType]: 'type',
  [FilterTypes.Methodology]: 'methodology',
  [FilterTypes.Standard]: 'standard',
};
