import API from '../API';
import appConfig from 'config/appConfig';
import { ICompanyDetailsResponseBody, ICompanyUpdateRequestBody } from 'types';

const companyAPI = {
  getMyCompany(): Promise<ICompanyDetailsResponseBody> {
    return API.get<ICompanyDetailsResponseBody>(`${appConfig.serviceUrl}/api/companies/my`).then(
      (response) => response.data,
    );
  },

  updateMyCompany(values: ICompanyUpdateRequestBody): Promise<Array<string>> {
    return API.patch<ICompanyUpdateRequestBody, Array<string>>(`${appConfig.serviceUrl}/api/companies/my`, values).then(
      (response) => response.data,
    );
  },

  submitMyCompany(): Promise<void> {
    return API.put<void, void>(`${appConfig.serviceUrl}/api/companies/my/submit`).then((response) => response.data);
  },
};

export default companyAPI;
