import { thunk } from 'easy-peasy';

import appConfig from 'config/appConfig';
import { ErrorTypes } from 'constants/index';
import { IStoreModel, ITokenAttrs, IWalletThunks, MessageItem, WalletActionsAndThunks } from 'types';
import addressVerificationAPI from 'api/user/addressVerificationAPI';

export const thunks: IWalletThunks = {
  init: thunk<WalletActionsAndThunks, unknown, unknown, IStoreModel>(async (actions, payload, { getStoreState }) => {
    const wallet = getStoreState().blockchain.wallet;
    const ethereum = (window as any).ethereum;
    if (!wallet.provider || !ethereum) {
      return;
    }

    await actions.checkConnection(null);

    ethereum.on('chainChanged', (chainId: number) => {
      console.log('chainChanged', chainId);
      actions.setChainId(chainId);
    });

    ethereum.on('accountsChanged', ([connectedAccount]: Array<string>) => {
      console.log('accountsChanged', connectedAccount);
      if (connectedAccount) {
        actions.setWalletAddress(connectedAccount);
      } else {
        actions.disconnect();
      }
    });

    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: appConfig.chainConfig.chainId }],
      });
    } catch (e) {
      ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [appConfig.chainConfig],
      });
    }
  }),
  connect: thunk<WalletActionsAndThunks, unknown, unknown, IStoreModel>(async (actions, payload, { getStoreState }) => {
    const wallet = getStoreState().blockchain.wallet;

    if (!wallet.provider) {
      throw new Error(ErrorTypes.NoMetamask);
    }

    await wallet.provider.send('eth_requestAccounts', []);
    const connectedAccount = await wallet.provider.getSigner().getAddress();
    const { chainId } = await wallet.provider.getNetwork();

    actions.setConnected(true);
    actions.setWalletAddress(connectedAccount);
    actions.setChainId(chainId);
  }),
  checkConnection: thunk<WalletActionsAndThunks, unknown, unknown, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      const wallet = getStoreState().blockchain.wallet;

      if (wallet.isConnected) {
        if (wallet.provider) {
          try {
            const [connectedAccount] = await wallet.provider.send('eth_requestAccounts', []);
            const { chainId } = await wallet.provider.getNetwork();
            if (connectedAccount) {
              actions.setWalletAddress(connectedAccount);
              actions.setChainId(chainId);
            } else {
              actions.disconnect();
            }
          } catch (error) {
            actions.disconnect();
          }
        } else {
          actions.disconnect();
        }
      }
    },
  ),
  verifyAddress: thunk<WalletActionsAndThunks, Array<MessageItem>, unknown, IStoreModel>(
    async (actions, payload, { getStoreState, getStoreActions }) => {
      const storeState = getStoreState();
      const storeActions = getStoreActions();
      const wallet = storeState.blockchain.wallet;

      if (!wallet.provider) {
        throw new Error(ErrorTypes.NoMetamask);
      }

      if (wallet.walletAddress) {
        const signature = await wallet.provider.send('eth_signTypedData', [payload, wallet.walletAddress]);
        await addressVerificationAPI.verifyAddress({ signature });
        await storeActions.user.loadProfile();
      }
    },
  ),
  addNewToken: thunk<WalletActionsAndThunks, ITokenAttrs, unknown, IStoreModel, Promise<void>>(
    async (actions, payload, { getStoreState }) => {
      const storeState = getStoreState();
      const wallet = storeState.blockchain.wallet;

      if (!wallet.provider) {
        throw new Error(ErrorTypes.NoMetamask);
      }

      const ethereum = (window as any).ethereum;
      await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: payload.address,
            symbol: payload.symbol,
            decimals: payload.decimals || 18,
            image: payload.image,
          },
        },
      });
    },
  ),
};
