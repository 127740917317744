import { ProjectStatus } from 'constants/project';
import { Icons } from 'types';

export type StatusDetails = {
  icon: Icons;
  message: string;
};

export const findStatusIcon = (status: ProjectStatus) => {
  const statusDetails = {} as StatusDetails;
  switch (status) {
    case ProjectStatus.Created:
      statusDetails.icon = 'created';
      statusDetails.message = 'tooltip.continue';
      break;
    case ProjectStatus.Pending:
      statusDetails.icon = 'pending';
      statusDetails.message = 'tooltip.waiting';
      break;
    case ProjectStatus.ApproveInProgress:
      statusDetails.icon = 'pending';
      statusDetails.message = 'tooltip.waiting';
      break;
    case ProjectStatus.InReview:
      statusDetails.icon = 'pending';
      statusDetails.message = 'tooltip.waiting';
      break;
    case ProjectStatus.Rejected:
      statusDetails.icon = 'rejected';
      statusDetails.message = 'tooltip.rejected';
      break;
    case ProjectStatus.Approved:
      statusDetails.icon = 'approved';
      statusDetails.message = 'tooltip.approved';
      break;
    default:
      statusDetails.message = '';
      statusDetails.icon = 'pending';
  }
  return statusDetails;
};
