import { memo, useState } from 'react';
import moment, { Moment } from 'moment';
import { DatePicker, DatePickerProps, Form } from 'antd';
import cn from 'classnames';

import { IBasicInputProps } from 'types/components/inputs';

import styles from './Datepicker.module.scss';
import { FieldAttributes } from 'formik';

interface IProps extends IBasicInputProps<Moment | null>, Omit<DatePickerProps, 'name' | 'onChange' | 'onBlur'> {
  field?: FieldAttributes<any>;
  fieldlabel?: string;
  showTime?: boolean;
  isDisableDates?: boolean;
}

const DatePickerInput = ({
  error,
  onChange,
  className = '',
  showTime,
  value,
  fieldlabel = '',
  field = '',
  isDisableDates = false,
  ...rest
}: IProps) => {
  const [pickedValue, setPickedValue] = useState(field?.value || null);
  const [focus, setFocus] = useState(false);
  const handleChange = (date: Moment | null) => {
    onChange && onChange(date);
    setPickedValue(date);
  };

  const disabledDate = (current: Moment) => {
    return current && current < moment().startOf('day') ? true : false;
  };

  const disabledTime = (current: Moment | null) => {
    if (!current) {
      return {
        disabledHours: () => Array.from({ length: 24 }, (_, i) => i),
        disabledMinutes: () => Array.from({ length: 60 }, (_, i) => i),
      };
    }

    if (current.isSame(moment(), 'day')) {
      const currentHour = moment().hour();
      const currentMinute = moment().minute();

      return {
        disabledHours: () => Array.from({ length: 24 }, (_, i) => i).filter((hour) => hour < currentHour),
        disabledMinutes: (selectedHour: number) => {
          if (selectedHour === currentHour) {
            return Array.from({ length: 60 }, (_, i) => i).filter((minute) => minute < currentMinute);
          }
          return [];
        },
      };
    }

    return {};
  };

  const DATE_FORMAT = showTime ? 'DD MMM, yyyy, HH:mm' : 'DD MMM, yyyy';

  return (
    <Form.Item help={error} validateStatus={error ? 'error' : ''}>
      {fieldlabel && (pickedValue || focus) && <div className={styles.fieldLabel}>{fieldlabel}</div>}
      <DatePicker
        {...rest}
        value={value ? moment(value) : undefined}
        defaultValue={value ? moment(value) : undefined}
        format={DATE_FORMAT}
        onChange={handleChange}
        showTime={showTime && { format: 'HH:mm' }}
        disabledDate={isDisableDates ? disabledDate : undefined}
        disabledTime={showTime && isDisableDates ? disabledTime : undefined}
        allowClear
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        className={cn(styles.datePicker, className, {
          [styles.borderVisible]: fieldlabel && (pickedValue || focus),
        })}
      />
    </Form.Item>
  );
};

export default memo(DatePickerInput);
