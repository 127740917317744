import { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router';

import { UserRole } from '../constants/user';
import { Routes } from '../constants/routes';
import { useStoreState } from 'easy-peasy';
import { IStoreModel } from '../types/store/store';

interface IHookOption {
  isAuthRequired?: boolean;
  isRedirect?: boolean;
  redirectRoute?: Routes;
  rolesRequired?: Array<UserRole>;
}

interface IHookResponse {
  hasAccess?: boolean;
  userRole?: UserRole;
}

const useAuthRole = ({
  isAuthRequired = true,
  isRedirect = true,
  redirectRoute = Routes.home,
  rolesRequired = [],
}: IHookOption = {}): IHookResponse => {
  const isAuthorized = useStoreState<IStoreModel>((store) => store.auth.isAuthorized);
  const userRole = useStoreState<IStoreModel, UserRole | undefined>((store) => store.user.profile?.role);
  const history = useHistory();
  const hasAuthAccess = isAuthRequired ? isAuthorized : !isAuthorized;
  const hasRoleAccess = !rolesRequired?.length || (!!userRole && rolesRequired.includes(userRole));
  const hasAccess = hasAuthAccess && hasRoleAccess;

  useEffect(() => {
    if (!hasAccess && isRedirect) {
      history.replace(redirectRoute);
    }
  }, [history, hasAccess, isRedirect, redirectRoute]);

  return useMemo(() => ({ hasAccess, userRole }), [hasAccess, userRole]);
};

export default useAuthRole;
