export enum UserRole {
  Admin = 'admin',
  Company = 'company',
  Project = 'project',
  Superadmin = 'superAdmin',
}

export enum UserStatus {
  Pending = 'pending',
  Approved = 'approved',
}
