import { ITrading, ITradingActions, ITradingState } from '../../types';
import { action } from 'easy-peasy';

export const actions: ITradingActions = {
  setSelectedTrade: action<ITradingState, ITrading>((state, payload) => {
    state.selectedTrade = payload;
  }),
  startTradeExecution: action<ITradingState>((state) => {
    state.tradeExecutionInProgress = true;
  }),
  stopTradeExecution: action<ITradingState>((state) => {
    state.tradeExecutionInProgress = false;
  }),
  startTradeCreation: action<ITradingState>((state) => {
    state.tradeCreationInProgress = true;
  }),
  stopTradeCreation: action<ITradingState>((state) => {
    state.tradeCreationInProgress = false;
  }),
  setTradeCreationError: action<ITradingState, boolean>((state, payload) => {
    state.tradeCreationError = payload;
  }),
  setTradeExecutionError: action<ITradingState, boolean>((state, payload) => {
    state.tradeExecutionError = payload;
  }),
};
