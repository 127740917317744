import { ReactNode } from 'react';

import { IMainLayoutMenuLink } from 'types';
import { Routes } from 'constants/routes';
import { UserRole } from 'constants/user';

import BasicMainLayoutView from '../basic/BasicMainLayoutView';
import InviteAdminView from './invite-admin/InviteAdminView';

interface IProps {
  children?: ReactNode;
}

const menuLinks: Array<IMainLayoutMenuLink> = [
  {
    key: '1',
    icon: 'dashboard',
    name: 'dashboard',
    path: Routes.superAdminDashboard,
    roles: [UserRole.Superadmin],
  },
  {
    key: '2',
    icon: 'projects',
    name: 'projects',
    path: Routes.superAdminProjects,
    roles: [UserRole.Superadmin],
  },
  {
    key: '3',
    icon: 'company',
    name: 'companies',
    path: Routes.superAdminCompanies,
    roles: [UserRole.Superadmin],
  },
  {
    key: '4',
    icon: 'avatar',
    name: 'admins',
    path: Routes.superAdminUsers,
    roles: [UserRole.Superadmin],
  },
  {
    key: '5',
    icon: 'requests',
    name: 'requests',
    path: Routes.superAdminTokensRequests,
    roles: [UserRole.Superadmin],
  },
];

const SuperaAdminMainLayoutView = ({ children }: IProps) => {
  return (
    <BasicMainLayoutView menuLinks={menuLinks} sidebarActions={<InviteAdminView />}>
      {children}
    </BasicMainLayoutView>
  );
};

export default SuperaAdminMainLayoutView;
