export enum Environment {
  Production = 'production',
  Development = 'development',
  Staging = 'staging',
}

export const REQUEST_TIMEOUT = 20000;

export const DEFAULT_PAGE_SIZE = 10;

export const VERIFICATION_MESSAGE_REFRESH_INTERVAL = 4 * 60 * 1000; // 4 minutes

export const TABS_ITEMS_PER_PAGE = 5;

export const MAX_SUGGESTION_INDEX = 4;
