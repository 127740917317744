import { IDocumentResponseBody } from 'types';
import { IPaginationResponseBody } from './common';
import { IProjectDetailsResponseBody } from './project';
import { ICreateDocumentRequestBody } from './document';

export enum IssueTokenStatus {
  Pending = 'pending',
  InReview = 'in-review',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum IssueTokenType {
  issueTokensRequest = 'Issue Tokens Request',
  requestTokens = 'Increase Emission Reduction',
}

export const tokenRequestTypeMap: any = {
  ['Issue Tokens Request']: 'issueTokensRequest',
  ['Increase Emission Reduction']: 'requestTokens',
};

export interface IIssueTokens {
  id: number;
  createdAt: string;
  projectId: number;
  status: IssueTokenStatus;
  type: IssueTokenType;
  amount: number;
  documents?: Array<IDocumentResponseBody> | null;
  reviewComment: string | null;
  txHash: string | null;
  accountAddress: string;
  project: Partial<IProjectDetailsResponseBody>;
}

export interface IIssueTokensList extends Omit<IIssueTokens, 'project'> {
  projectParentId: number;
  tokenAddr: string;
  projectName: string;
}

export interface IIssueTokensCreateRequest {
  amount: string;
  type: IssueTokenType;
  documents?: Array<ICreateDocumentRequestBody>;
}

export interface IIssueTokensUpdateRequest {
  status: IssueTokenStatus;
  reviewComment?: string;
  txHash?: string;
}

export type ITokensRequestsResponseBody = IPaginationResponseBody<IIssueTokensList>;
