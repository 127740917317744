import { thunk } from 'easy-peasy';

import { DocumentActionsAndThunks, IDocumentThunks, IUploadDocumentInputsPayload, IUploadDocumentPayload } from 'types';
import documentAPI from 'api/document/documentAPI';

export const thunks: IDocumentThunks = {
  uploadDocuments: thunk<DocumentActionsAndThunks, Array<IUploadDocumentPayload>>(async (actions, payload) => {
    actions.setDocumentsLoading(true);
    try {
      await Promise.all(
        payload.map(({ uploadUrl, file }) => {
          return documentAPI.uploadDocument(uploadUrl, file);
        }),
      );
    } finally {
      actions.setDocumentsLoading(false);
    }
  }),

  uploadDocumentInputs: thunk<DocumentActionsAndThunks, IUploadDocumentInputsPayload>(async (actions, payload) => {
    return actions.uploadDocuments(
      payload.documents
        .filter(({ id }) => !id)
        .map(({ originFileObj }, index) => ({
          uploadUrl: payload.uploadUrls[index],
          file: originFileObj as File,
        })),
    );
  }),
};
