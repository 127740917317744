import get from 'lodash.get';

export const getServerErrorMessage = (error: unknown): string => {
  const serverError = get(error, 'response.data.message');

  if (Array.isArray(serverError)) {
    if (typeof serverError[0] === 'string') {
      return serverError.join(', ');
    }

    if (typeof serverError[0] === 'object' && Array.isArray(serverError[0].errors)) {
      return serverError.map(({ errors = [] }) => errors.join(', ')).join('. ');
    }
  }

  return serverError;
};

export const getErrorMessage = (error: unknown): string => {
  const serverError = getServerErrorMessage(error);
  return serverError || get(error, 'message', 'Unknown error');
};
