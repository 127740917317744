export enum SortingTypes {
  Date = 'Date',
  Popularity = 'Popularity',
  ByEmissionReduction = 'By emission reduction',
}

export const mapSortingParams = {
  [SortingTypes.Date]: { sortBy: 'createdAt' },
  [SortingTypes.Popularity]: { sortBy: 'score' },
  [SortingTypes.ByEmissionReduction]: { sortBy: 'emissionReductions' },
};

export enum SortingOrder {
  ASC = '↓',
  DESC = '↑',
}

export const mapSortOrderParams = {
  [SortingOrder.ASC]: { sortOrder: 'asc' },
  [SortingOrder.DESC]: { sortOrder: 'desc' },
};
