import { IUserState } from 'types';
import { getFromLS } from 'utils';
import { LS_KEY_USER_PROFILE } from 'constants/index';

const profileFromStorage = getFromLS(LS_KEY_USER_PROFILE, {});

export const initState: IUserState = {
  profile: profileFromStorage?.userId ? profileFromStorage : null,
  isProfileLoading: false,
};
