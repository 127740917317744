import { AdminProjectsStore } from '../../../types/store/admin/projects';
import { initState } from './initState';
import { actions } from './actions';
import { thunks } from './thunks';

export const projects: AdminProjectsStore = {
  ...initState,
  ...actions,
  ...thunks,
};
