import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getFromLS, setToLS, checkAccessTokenExpired } from 'utils';
import { LS_KEY_ACCESS_TOKEN, LS_KEY_REFRESH_TOKEN } from 'constants/index';
import { IRefreshTokensResponseBody } from 'types';
import { store } from '../store';
import authAPI from './auth/authAPI';

interface ConfigModel extends AxiosRequestConfig {
  isPublic?: boolean;
}

const instance = axios.create({
  // timeout: REQUEST_TIMEOUT,
});
let refreshTokensPromise: null | Promise<IRefreshTokensResponseBody> = null;

// Set access token to Authorization header for each secured request
instance.interceptors.request.use(async (config: ConfigModel) => {
  if (!config.isPublic) {
    // If Tokens are refreshing, wait for new tokens
    if (refreshTokensPromise) {
      await refreshTokensPromise;
    }

    const accessToken = getFromLS(LS_KEY_ACCESS_TOKEN);

    if (accessToken) {
      config.headers || (config.headers = {});
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  }
  return config;
});

// Refresh tokens if access token expired
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { status } = error.response || {};

    if (status === 401) {
      const accessToken = getFromLS(LS_KEY_ACCESS_TOKEN);
      const refreshToken = getFromLS(LS_KEY_REFRESH_TOKEN);
      const shouldRefreshTokens =
        !error.config.isPublic && !refreshTokensPromise && !!refreshToken && checkAccessTokenExpired(accessToken);

      if (shouldRefreshTokens) {
        try {
          refreshTokensPromise = authAPI.refreshTokens({ accessToken, refreshToken });
          const refreshData = await refreshTokensPromise;
          refreshTokensPromise = null;

          setToLS(LS_KEY_ACCESS_TOKEN, refreshData.accessToken);
          setToLS(LS_KEY_REFRESH_TOKEN, refreshData.refreshToken);

          return axios.request({
            ...error.config,
            headers: {
              ...error.config.headers,
              Authorization: `Bearer ${refreshData.accessToken}`,
            },
          });
        } catch (error) {
          store.getActions().auth.signOut();
          throw error;
        }
      }
      store.getActions().auth.signOut();
    }

    return Promise.reject(error);
  },
);

// ** API instance for all requests **
export default {
  get<ResBody>(path: string, config?: ConfigModel): Promise<AxiosResponse<ResBody>> {
    return instance.get(path, config);
  },

  post<ReqBody, ResBody>(path: string, data?: ReqBody, config?: ConfigModel): Promise<AxiosResponse<ResBody>> {
    return instance.post(path, data, config);
  },

  put<ReqBody, ResBody>(path: string, data?: ReqBody, config?: ConfigModel): Promise<AxiosResponse<ResBody>> {
    return instance.put(path, data, config);
  },

  patch<ReqBody, ResBody>(path: string, data?: ReqBody, config?: ConfigModel): Promise<AxiosResponse<ResBody>> {
    return instance.patch(path, data, config);
  },

  delete<ResBody>(path: string, config?: ConfigModel): Promise<AxiosResponse<ResBody>> {
    return instance.delete(path, config);
  },
};
