// Keccak-256 of smart contract methods
export enum MethodSignatures {
  RegisterProject = '4018382164d085e6a52787a711b4fe4c5bc3e333c7019a34b55cc3bc6625d3cb', // registerProject(address,string,string,string)
  RegisterCompany = '9a3867e93b5a615c57aeecbd39c05281cf493fe277fd82bd2ae6314e009a0137', // registerConsumer(address,string)
  Mint = '40c10f19c047ae7dfa66d6312b683d2ea3dfbcb4159e96b967c5f4b0a86f2842', // mint(address,uint256)
  Burn = '9dc29fac0ba6d4fc521c69c2b0c636d612e3343bc39ed934429b8876b0d12cba', // burn(CarbonCreditToken,uint256)
  MintToken = '79c65068f81072733b15ab3cba61b23110793f90ab099d228a414b186333a81e', // mintToken(address,uint256)
  TransferToken = 'a9059cbb2ab09eb219583f4a59a5d0623ade346d962bcd4e46b11da047c9049b', // transfer(address,uint256)
  Deposit = 'd0e30db03f2e24c6531d8ae2f6c09d8e7a6ad7f7e87a81cb75dfda61c9d83286', // deposit()
  Withdraw = '2e1a7d4d13322e7b96f9a57413e1525c250fb7a9021cf91d1540d5b69f16a49f', // withdraw(uint)
  Approve = '095ea7b334ae44009aa867bfb386f5c3b4b443ac6f0ee573fa91c4608fbadfba', // approve(address,uint256)
  TransferBridge = '05e2ca1797c4a44e61ad0c2a286a70c5322827f641c5e3d132c97f2d8f7751e6', // deposit(uint8,bytes32,bytes)
}

export const ZERO_ADDRESS = `0x${'0'.repeat(40)}`;
