import isEmpty from 'lodash.isempty';

import { MethodSignatures } from 'constants/blockchain';

export function isChainIdsEqual(id1?: number | null, id2?: number | null) {
  return Number(id1) === Number(id2);
}

export function isAddressesEqual(address1?: string | null, address2?: string | null) {
  return !isEmpty(address1) && String(address1).toLowerCase() === String(address2).toLowerCase();
}

export function calculateTxCost(gasPrice = '0', gasUsed = '0') {
  return BigInt(gasPrice) * BigInt(gasUsed);
}

export function isTxMethod(messagePayload: string, signature: MethodSignatures) {
  return messagePayload.indexOf(signature.slice(0, 8)) === 0;
}
