import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { matchPath, useLocation } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import { Routes } from 'constants/index';
import { IStoreModel } from 'types';

const useWalletVerification = () => {
  const isAuthorized = useStoreState<IStoreModel>((store) => store.auth.isAuthorized);
  const isVerified = useStoreState<IStoreModel, boolean>((store) => !!store.user.profile?.isVerified);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const verificationRequired = isAuthorized && !isVerified;
    const isAddressVerificationPage = matchPath(location.pathname, {
      path: Routes.walletVerification,
      exact: true,
    });

    if (verificationRequired && !isAddressVerificationPage) {
      history.replace(Routes.walletVerification);
    }

    if (!verificationRequired && isAddressVerificationPage) {
      history.replace(Routes.home);
    }
  }, [history, isAuthorized, isVerified, location.pathname]);
};

export default useWalletVerification;
