import { ProjectStore } from 'types';
import { initState } from './initState';
import { actions } from './actions';
import { thunks } from './thunks';

export const project: ProjectStore = {
  ...initState,
  ...actions,
  ...thunks,
};
