import { IAuctionState } from 'types/store';

export const initState: IAuctionState = {
  auctionInfo: null,
  auctionBidsInfo: null,
  auctionList: null,
  isApproved: false,
  isLoading: false,
  error: null,
};
