import { BigNumber, ethers } from 'ethers';
import { JsonRpcSigner } from '@ethersproject/providers';
import { TransactionResponse } from '@ethersproject/abstract-provider';

import appConfig from 'config/appConfig';
import { ZERO_ADDRESS } from 'constants/blockchain';

export class TradingContract {
  contract: ethers.Contract;
  provider: ethers.providers.JsonRpcProvider = new ethers.providers.JsonRpcProvider(appConfig.rpcEndpoint);

  constructor(signer: JsonRpcSigner) {
    this.contract = new ethers.Contract(appConfig.contracts.Trading.address, appConfig.contracts.Trading.abi, signer);
  }

  async create(
    address: string,
    amount: BigNumber,
    price: BigNumber,
    bid: boolean,
    paymentToken = ZERO_ADDRESS,
  ): Promise<{ txHash: string; offerId: number }> {
    const options: { value?: BigNumber } = {};

    if (!bid) {
      options['value'] = price;
    }

    const tx = (await this.contract.createBidAsk(
      address,
      amount,
      price,
      bid,
      paymentToken,
      options,
    )) as TransactionResponse;

    const receipt = await tx.wait();

    const filteredLogs = receipt.logs.filter(
      (log: ethers.providers.Log) => log.address === appConfig.contracts.Trading.address,
    );

    const AbiCoder = new ethers.utils.AbiCoder();
    const decodedData = AbiCoder.decode(
      ['uint256', 'address', 'uint256', 'uint256', 'address', 'bool', 'address'],
      filteredLogs[0].data,
    );
    return { txHash: tx.hash, offerId: decodedData[0].toNumber() };
  }

  async execute(offerId: number, amount: BigNumber, options = {}) {
    return this.contract.acceptBidAsk(offerId, amount, options);
  }

  async delete() {
    return this.contract.deleteBidAsk();
  }
}
