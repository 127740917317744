import { ethers } from 'ethers';
import { TransactionResponse } from '@ethersproject/abstract-provider';

import appConfig from 'config/appConfig';

export class Provider {
  static rpcProvider = new ethers.providers.JsonRpcProvider(appConfig.rpcEndpoint);

  static async getBalance(accountAddress: string): Promise<string> {
    return ethers.utils.formatEther(await Provider.rpcProvider.getBalance(accountAddress));
  }

  static async getTransaction(txHash: string): Promise<TransactionResponse> {
    return Provider.rpcProvider.getTransaction(txHash);
  }
}
