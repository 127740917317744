import { TradingStore } from '../../types';
import { initState } from './initState';
import { actions } from './actions';
import { thunks } from './thunks';

export const trading: TradingStore = {
  ...initState,
  ...actions,
  ...thunks,
};
