import { thunk } from 'easy-peasy';
import pick from 'lodash.pick';

import {
  IFilterRequestParams,
  AdminUsersActionsAndThunks,
  IStoreModel,
  IAdminUsersThunks,
  IRejectUserPayload,
  IAdminUserResponseBody,
  IDeleteAdminUserPayload,
} from 'types';
import adminUsersAPI from 'api/admin/adminUsersAPI';
import { ErrorTypes, UserRole } from 'constants/index';
import { PlatformContract } from 'blockchain';

export const thunks: IAdminUsersThunks = {
  loadAdminUsers: thunk<AdminUsersActionsAndThunks, IFilterRequestParams, unknown, IStoreModel>(
    async (actions, payload) => {
      actions.startUsersLoading();
      try {
        const users = await adminUsersAPI.getUsers({
          ...pick(payload, ['name', 'status', 'page', 'limit', 'createdAt']),
          role: UserRole.Admin,
        });
        actions.setUsers(users);
      } finally {
        actions.stopUsersLoading();
      }
    },
  ),

  approveUser: thunk<AdminUsersActionsAndThunks, IAdminUserResponseBody, unknown, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      const wallet = getStoreState().blockchain.wallet;

      if (!wallet.provider) {
        throw new Error(ErrorTypes.NoMetamask);
      }

      const contract = new PlatformContract(wallet.provider.getSigner());

      const hasSuperAdminRole = await contract.hasSuperAdminRole(wallet.walletAddress!);

      if (!hasSuperAdminRole) {
        throw new Error(ErrorTypes.AdminPermissionError);
      }

      const hasAdminRole = await contract.hasAdminRole(payload.accountAddress);

      if (!hasAdminRole) {
        await contract.registerAdmin(payload.accountAddress);
        return await adminUsersAPI.approveAdminUser(payload.id);
      }

      throw new Error(ErrorTypes.AdminAccountAddress);
    },
  ),

  rejectUser: thunk<AdminUsersActionsAndThunks, IRejectUserPayload, unknown, IStoreModel>(async (actions, payload) => {
    await adminUsersAPI.rejectUser(payload.id);
  }),

  deleteAdminUser: thunk<AdminUsersActionsAndThunks, IDeleteAdminUserPayload, unknown, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      const wallet = getStoreState().blockchain.wallet;

      if (!wallet.provider) {
        throw new Error(ErrorTypes.NoMetamask);
      }

      const contract = new PlatformContract(wallet.provider.getSigner());

      const hasSuperAdminRole = await contract.hasSuperAdminRole(wallet.walletAddress!);

      if (!hasSuperAdminRole) {
        throw new Error(ErrorTypes.AdminPermissionError);
      }

      const hasAdminRole = await contract.hasAdminRole(payload.accountAddress);

      if (hasAdminRole) {
        await contract.revokeAdminRole(payload.accountAddress);
        return await adminUsersAPI.deleteAdminUser(payload.id);
      }

      throw new Error(ErrorTypes.AdminAccountNotExist);
    },
  ),
};
