import API from '../API';

const documentAPI = {
  uploadDocument(uploadUrl: string, file: File): Promise<void> {
    return API.put<any, any>(uploadUrl, file, { isPublic: true, withCredentials: false }).then(
      (response) => response.data,
    );
  },
};

export default documentAPI;
