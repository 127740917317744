import { thunk } from 'easy-peasy';
import { IUserThunks, UserActionsAndThunks } from '../../types/store/user';
import profileAPI from '../../api/user/profileAPI';

export const thunks: IUserThunks = {
  loadProfile: thunk<UserActionsAndThunks>(async (actions) => {
    actions.startProfileLoading();
    const profile = await profileAPI.getMyProfile();
    actions.setProfile(profile);
    actions.stopProfileLoading();
  }),
};
