import * as Yup from 'yup';
import {
  IForgotPasswordForm,
  IInviteAdminForm,
  IResetPasswordForm,
  ISignInForm,
  ISignUpAdminForm,
  ISignUpBaseForm,
} from 'types';
import { documentsValidation, passwordValidation, phoneValidation } from './common';

export const SignInSchema: Yup.SchemaOf<ISignInForm> = Yup.object().shape({
  email: Yup.string().email('validation.invalidEmail').required('validation.required'),
  // TODO: ask about validation
  password: Yup.string(),
});

export const SignUpBaseSchema: Yup.SchemaOf<ISignUpBaseForm> = Yup.object().shape({
  email: Yup.string().email('validation.invalidEmail').required('validation.required'),
  password: passwordValidation.required('validation.required'),
  passwordConfirm: passwordValidation
    .oneOf([Yup.ref('password')], 'validation.wrongConfirmPassword')
    .required('validation.required'),
  name: Yup.string().max(200).required('validation.required'),
  surname: Yup.string().max(200).required('validation.required'),
  phoneNumber: phoneValidation.required('validation.required'),
  accountAddress: Yup.string()
    .matches(/^0x[a-fA-F0-9]{40}$/, 'validation.invalidAccountAddress')
    .required('validation.required'),
  companyName: Yup.string().max(200, 'validation.companyNameMax').required('validation.required'),
  userPosition: Yup.string().max(200).required('validation.required'),
  isAgreedToTerms: Yup.boolean().oneOf([true], 'validation.required').required('validation.required'),
});

export const SignUpCompanySchema = SignUpBaseSchema.shape({
  companyLegalDocuments: documentsValidation,
  founderLegalDocuments: documentsValidation,
});

export const SignUpAdminSchema: Yup.SchemaOf<ISignUpAdminForm> = Yup.object().shape({
  password: passwordValidation.required('validation.required'),
  passwordConfirm: passwordValidation
    .oneOf([Yup.ref('password'), ''], 'validation.wrongConfirmPassword')
    .required('validation.required'),
  name: Yup.string().max(200).required('validation.required'),
  surname: Yup.string().max(200).required('validation.required'),
  phoneNumber: phoneValidation.required('validation.required'),
  accountAddress: Yup.string()
    .matches(/^0x[a-fA-F0-9]{40}$/, 'validation.invalidAccountAddress')
    .required('validation.required'),
  isAgreedToTerms: Yup.boolean().oneOf([true], 'validation.required').required('validation.required'),
});

export const EmailSchema: Yup.SchemaOf<IInviteAdminForm | IForgotPasswordForm> = Yup.object().shape({
  email: Yup.string().email('validation.invalidEmail').required('validation.required'),
});

export const PasswordSchema: Yup.SchemaOf<IResetPasswordForm> = Yup.object().shape({
  password: passwordValidation.required('validation.required'),
  newPasswordConfirm: passwordValidation
    .oneOf([Yup.ref('password'), ''], 'validation.wrongConfirmPassword')
    .required('validation.required'),
});
