import API from '../API';
import appConfig from 'config/appConfig';
import { IFilterRequestParams, IAdminUsersResponseBody, IAdminUserResponseBody } from 'types';

const adminUsersAPI = {
  getUsers(params: IFilterRequestParams): Promise<IAdminUsersResponseBody> {
    return API.get<IAdminUsersResponseBody>(`${appConfig.serviceUrl}/api/admin/users`, {
      params,
    }).then((response) => response.data);
  },

  getUser(userId: string | number): Promise<IAdminUserResponseBody> {
    return API.get<IAdminUserResponseBody>(`${appConfig.serviceUrl}/api/admin/users/${userId}`).then(
      (response) => response.data,
    );
  },

  approveAdminUser(userId: string | number): Promise<void> {
    return API.post<void, void>(`${appConfig.serviceUrl}/api/admin/users/${userId}/approve`).then(
      (response) => response.data,
    );
  },

  deleteAdminUser(userId: string | number): Promise<void> {
    return API.delete<void>(`${appConfig.serviceUrl}/api/admin/users/${userId}`).then((response) => response.data);
  },

  rejectUser(userId: string | number): Promise<void> {
    return API.delete<void>(`${appConfig.serviceUrl}/api/admin/users/pending/${userId}`).then(
      (response) => response.data,
    );
  },
};

export default adminUsersAPI;
