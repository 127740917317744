import { WalletStore } from 'types';
import { initState } from './initState';
import { actions } from './actions';
import { thunks } from './thunks';

export const wallet: WalletStore = {
  ...initState,
  ...actions,
  ...thunks,
};
