import API from '../API';
import appConfig from 'config/appConfig';
import { IIssueTokensCreateRequest } from 'types';

const PROJECT_API_PREFIX = `${appConfig.serviceUrl}/api/projects`;

const projectRequestAPI = {
  issueTokens(values: IIssueTokensCreateRequest): Promise<Array<string>> {
    return API.post<IIssueTokensCreateRequest, Array<string>>(`${PROJECT_API_PREFIX}/my/issue-tokens`, values).then(
      (response) => response.data,
    );
  },

  cancelTokenIssue(id: number): Promise<void> {
    return API.delete<void>(`${PROJECT_API_PREFIX}/my/issue-tokens/${id}`).then((response) => response.data);
  },
};

export default projectRequestAPI;
