import { action } from 'easy-peasy';

import { ICompanyActions, ICompanyDetailsResponseBody, ICompanyState } from 'types';

export const actions: ICompanyActions = {
  setCompany: action<ICompanyState, ICompanyDetailsResponseBody | null>((state, payload) => {
    state.company = payload;
  }),
  setCompanyLoading: action<ICompanyState, boolean>((state, payload) => {
    state.isCompanyLoading = payload;
  }),
};
