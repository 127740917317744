import { createStore } from 'easy-peasy';
import { IStoreModel } from 'types';

import { user } from './user';
import { auth } from './auth';
import { admin } from './admin';
import { project } from './project';
import { company } from './company';
import { document } from './document';
import { blockchain } from './blockchain';
import { auction } from './auction';
import { trading } from './trading';

export const store = createStore<IStoreModel>(
  {
    user,
    auth,
    admin,
    project,
    company,
    document,
    blockchain,
    auction,
    trading,
  },
  { name: 'CarbcoinPlatform' },
);
