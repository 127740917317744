import API from '../API';
import appConfig from 'config/appConfig';
import {
  FilterParams,
  IMessageResponse,
  IPaginationResponse,
  IProjectDetailsResponseBody,
  IProjectEvent,
  IProjectFavoriteRequestBody,
  IProjectMilestone,
  IProjectsCount,
  IProjectsListResponseBody,
  IProjectStatement,
  IProjectSubmitAuditDocumentsRequestBody,
  IProjectUpdateRequestBody,
  IPublicationResponseBody,
  ITokenTickerExistsResponseBody,
} from 'types';

const PROJECT_API_PREFIX = `${appConfig.serviceUrl}/api/projects`;

const projectAPI = {
  getProjects(params: FilterParams): Promise<IPaginationResponse<IProjectsListResponseBody>> {
    return API.get<IPaginationResponse<IProjectsListResponseBody>>(`${PROJECT_API_PREFIX}`, { params }).then(
      (response) => response.data,
    );
  },

  getAllProjects(): Promise<IProjectDetailsResponseBody[]> {
    return API.get<IProjectDetailsResponseBody[]>(`${PROJECT_API_PREFIX}/all`).then((response) => response.data);
  },

  getProjectsCount(params: FilterParams): Promise<IProjectsCount> {
    return API.get<IProjectsCount>(`${PROJECT_API_PREFIX}/count`, { params }).then((response) => response.data);
  },

  getProjectById(id: number): Promise<IProjectDetailsResponseBody> {
    return API.get<IProjectDetailsResponseBody>(`${PROJECT_API_PREFIX}/${id}`).then((response) => response.data);
  },

  getMyProject(): Promise<IProjectDetailsResponseBody> {
    return API.get<IProjectDetailsResponseBody>(`${PROJECT_API_PREFIX}/my`).then((response) => response.data);
  },

  updateMyProject(values: IProjectUpdateRequestBody): Promise<Array<string>> {
    return API.patch<IProjectUpdateRequestBody, Array<string>>(`${PROJECT_API_PREFIX}/my`, values).then(
      (response) => response.data,
    );
  },

  submitAuditDocuments(values: IProjectSubmitAuditDocumentsRequestBody): Promise<Array<string>> {
    return API.patch<IProjectSubmitAuditDocumentsRequestBody, Array<string>>(
      `${PROJECT_API_PREFIX}/my/audit-documents-submit`,
      values,
    ).then((response) => response.data);
  },

  submitMyProject(): Promise<void> {
    return API.put<void, void>(`${PROJECT_API_PREFIX}/my/submit`).then((response) => response.data);
  },

  rollbackMyProject(): Promise<void> {
    return API.patch<void, void>(`${PROJECT_API_PREFIX}/my/rollback`).then((response) => response.data);
  },

  //STATEMENTS

  getProjectStatements(projectId: number): Promise<IPaginationResponse<IProjectStatement>> {
    return API.get<IPaginationResponse<IProjectStatement>>(`${PROJECT_API_PREFIX}/${projectId}/statements`).then(
      (response) => response.data,
    );
  },

  createProjectStatement(projectId: number, data: IProjectStatement): Promise<IProjectStatement> {
    return API.post<Omit<IProjectStatement, 'id'>, IProjectStatement>(
      `${PROJECT_API_PREFIX}/${projectId}/statements`,
      data,
    ).then((response) => response.data);
  },

  editProjectStatement(
    projectId: number,
    data: Omit<IProjectStatement, 'id'>,
    statementId: number,
  ): Promise<IProjectStatement> {
    return API.patch<Omit<IProjectStatement, 'id'>, IProjectStatement>(
      `${PROJECT_API_PREFIX}/${projectId}/statements/${statementId}`,
      data,
    ).then((response) => response.data);
  },

  deleteProjectStatement(projectId: number, statementId: number): Promise<IProjectStatement> {
    return API.delete<IProjectStatement>(`${PROJECT_API_PREFIX}/${projectId}/statements/${statementId}`).then(
      (response) => response.data,
    );
  },

  //PUBLICATIONS
  getProjectPublications({
    projectId,
    limit,
  }: {
    projectId: number;
    limit: number;
  }): Promise<IPaginationResponse<IPublicationResponseBody>> {
    return API.get<IPaginationResponse<IPublicationResponseBody>>(
      `${PROJECT_API_PREFIX}/${projectId}/publications?limit=${limit}`,
    ).then((response) => response.data);
  },

  createProjectPublication(projectId: number, data: IPublicationResponseBody): Promise<IPublicationResponseBody> {
    return API.post<Omit<IPublicationResponseBody, 'id'>, IPublicationResponseBody>(
      `${PROJECT_API_PREFIX}/${projectId}/publications`,
      data,
    ).then((response) => response.data);
  },

  editProjectPublication(
    projectId: number,
    data: IPublicationResponseBody,
    publicationId: number,
  ): Promise<IPublicationResponseBody> {
    return API.patch<Omit<IPublicationResponseBody, 'id'>, IPublicationResponseBody>(
      `${PROJECT_API_PREFIX}/${projectId}/publications/${publicationId}`,
      data,
    ).then((response) => response.data);
  },

  deleteProjectPublication(projectId: number, publicationtId: number): Promise<IPublicationResponseBody> {
    return API.delete<IPublicationResponseBody>(
      `${PROJECT_API_PREFIX}/${projectId}/publications/${publicationtId}`,
    ).then((response) => response.data);
  },

  //MILESTONES
  getProjectMilestones({
    projectId,
    limit,
  }: {
    projectId: number;
    limit: number;
  }): Promise<IPaginationResponse<Omit<IProjectMilestone, 'id'>>> {
    return API.get<IPaginationResponse<Omit<IProjectMilestone, 'id'>>>(
      `${PROJECT_API_PREFIX}/${projectId}/milestones?limit=${limit}`,
    ).then((response) => response.data);
  },

  createProjectMilestone(projectId: number, data: Omit<IProjectMilestone, 'id'>): Promise<IProjectMilestone> {
    return API.post<Omit<IProjectMilestone, 'id'>, IProjectMilestone>(
      `${PROJECT_API_PREFIX}/${projectId}/milestones`,
      data,
    ).then((response) => response.data);
  },

  editProjectMilestone(
    projectId: number,
    data: Omit<IProjectMilestone, 'id'>,
    milestoneId: number,
  ): Promise<IProjectMilestone> {
    return API.patch<Omit<IProjectMilestone, 'id'>, IProjectMilestone>(
      `${PROJECT_API_PREFIX}/${projectId}/milestones/${milestoneId}`,
      data,
    ).then((response) => response.data);
  },

  deleteProjectMilestone(projectId: number, milestoneId: number): Promise<IProjectMilestone> {
    return API.delete<IProjectMilestone>(`${PROJECT_API_PREFIX}/${projectId}/milestones/${milestoneId}`).then(
      (response) => response.data,
    );
  },

  //EVENTS
  getProjectEvents({
    projectId,
    limit,
  }: {
    projectId: number;
    limit: number;
  }): Promise<IPaginationResponse<Omit<IProjectEvent, 'id'>>> {
    return API.get<IPaginationResponse<Omit<IProjectEvent, 'id'>>>(
      `${PROJECT_API_PREFIX}/${projectId}/events?limit=${limit}`,
    ).then((response) => response.data);
  },

  createProjectEvent(projectId: number, data: Omit<IProjectEvent, 'id'>): Promise<IProjectEvent> {
    return API.post<Omit<IProjectEvent, 'id'>, IProjectEvent>(`${PROJECT_API_PREFIX}/${projectId}/events`, data).then(
      (response) => response.data,
    );
  },

  editProjectEvent(projectId: number, data: Omit<IProjectEvent, 'id'>, eventId: number): Promise<IProjectEvent> {
    return API.patch<Omit<IProjectEvent, 'id'>, IProjectEvent>(
      `${PROJECT_API_PREFIX}/${projectId}/events/${eventId}`,
      data,
    ).then((response) => response.data);
  },

  deleteProjectEvent(projectId: number, eventId: number): Promise<IProjectEvent> {
    return API.delete<IProjectEvent>(`${PROJECT_API_PREFIX}/${projectId}/events/${eventId}`).then(
      (response) => response.data,
    );
  },

  // FAVORITES
  addToFavorites(id: number): Promise<IMessageResponse> {
    return API.post<IProjectFavoriteRequestBody, IMessageResponse>(`${PROJECT_API_PREFIX}/favorites`, {
      projectId: id,
    }).then((response) => response.data);
  },

  removeFromFavorites(id: number): Promise<IMessageResponse> {
    return API.delete<IMessageResponse>(`${PROJECT_API_PREFIX}/favorites/${id}`).then((response) => response.data);
  },

  getFavoriteProjects(params: FilterParams): Promise<IPaginationResponse<IProjectsListResponseBody>> {
    return API.get<IPaginationResponse<IProjectsListResponseBody>>(`${PROJECT_API_PREFIX}/favorites`, { params }).then(
      (response) => response.data,
    );
  },

  checkTicker(tokenTicker: string): Promise<ITokenTickerExistsResponseBody> {
    return API.post<{ tokenTicker: string }, ITokenTickerExistsResponseBody>(`${PROJECT_API_PREFIX}/check-ticker`, {
      tokenTicker,
    }).then((response) => response.data);
  },
};

export default projectAPI;
