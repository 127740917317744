export default {
  testnet: {
    chainId: '0x13882',
    chainName: 'Polygon Testnet',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: [process.env.REACT_APP_RPC_ENDPOINT],
    blockExplorerUrls: [process.env.REACT_APP_EXPLORER_URL],
    iconUrls: [''],
  },
};
