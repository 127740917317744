import { useState } from 'react';
import { useLocation } from 'react-router';
import { ParsedQs } from 'qs';
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import { parseQueryParams } from 'utils/query';
import { FilterParams } from 'types/components/filter';

const useQueryParams = (): [ParsedQs, (params: any) => any] => {
  const location = useLocation();
  const [search, setSearch] = useState<ParsedQs>(parseQueryParams(location.search));

  const setQueryParams = (params: FilterParams | any) => {
    let query = search;

    if (isEmpty(params) || !get(params, 'search')) {
      setSearch(params);
      return;
    }

    Object.keys(params).forEach((name) => {
      const value = params[name];
      if (['', undefined].includes(value)) {
        delete query[name];
      } else {
        query = { ...query, [name]: value };
      }
    });
    setSearch(query);
  };

  return [search, setQueryParams];
};

export default useQueryParams;
