export enum RegistrationChartRange {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export interface IAdminRegistrationChartRequestParams {
  range: RegistrationChartRange;
}

export interface IRegistrationItemChartResponseBody {
  total: number;
  values: Array<number>;
}

export interface IRegistrationChartResponseBody {
  axis: Array<string>;
  project: IRegistrationItemChartResponseBody;
  company: IRegistrationItemChartResponseBody;
}
