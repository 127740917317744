import { action } from 'easy-peasy';
import {
  IProjectActions,
  IProjectState,
  IPaginationResponse,
  IProjectDetailsResponseBody,
  IProjectsListResponseBody,
  IPublicationResponseBody,
  IIssueTokens,
  IProjectMilestone,
  IProjectStatement,
  IProjectEvent,
} from 'types';
import { ProjectStatus } from 'constants/project';

export const actions: IProjectActions = {
  setProjectsList: action<IProjectState, IPaginationResponse<IProjectsListResponseBody>>((state, payload) => {
    state.projectsList = payload;
  }),
  setTopProjectsList: action<IProjectState, IPaginationResponse<IProjectsListResponseBody>>((state, payload) => {
    state.topProjectsList = payload;
  }),
  setTopEmissionReductionsProjectsList: action<IProjectState, IPaginationResponse<IProjectsListResponseBody>>(
    (state, payload) => {
      state.topEmissionReductionsProjectsList = payload;
    },
  ),
  setNewProjectsList: action<IProjectState, IPaginationResponse<IProjectsListResponseBody>>((state, payload) => {
    state.newProjectsList = payload;
  }),
  setProjectById: action<IProjectState, IProjectDetailsResponseBody>((state, payload) => {
    state.projectById = payload;
  }),
  setFavoriteProjectById: action<IProjectState, boolean>((state, payload) => {
    if (state.projectById) {
      state.projectById.isFavorite = payload;
    }
  }),
  setProject: action<IProjectState, IProjectDetailsResponseBody>((state, payload) => {
    state.project = payload;
  }),
  setProjectStatus: action<IProjectState, ProjectStatus>((state, payload) => {
    if (state.project?.status) {
      state.project.status = payload;
    }
  }),
  setProjectLoading: action<IProjectState, boolean>((state, payload) => {
    state.isProjectLoading = payload;
  }),
  setProjectIssueTokensRequest: action<IProjectState, IIssueTokens>((state, payload) => {
    if (state.project?.issueTokensRequest === null) {
      state.project.issueTokensRequest = payload;
    }
  }),

  //STATEMENTS
  setProjectStatements: action<IProjectState, IPaginationResponse<IProjectStatement>>((state, payload) => {
    state.projectStatements = payload;
  }),

  //PUBLICATIONS
  setProjectPublications: action<IProjectState, IPaginationResponse<IPublicationResponseBody>>((state, payload) => {
    state.projectPublications = payload;
  }),

  //MILESTONES
  setProjectMilestones: action<IProjectState, IPaginationResponse<IProjectMilestone>>((state, payload) => {
    state.projectMilestones = payload;
  }),

  //EVENTS
  setProjectEvents: action<IProjectState, IPaginationResponse<IProjectEvent>>((state, payload) => {
    state.projectEvents = payload;
  }),

  // FAVORITES
  setFavoriteProjectsList: action<IProjectState, IPaginationResponse<IProjectsListResponseBody>>((state, payload) => {
    state.favoriteProjectsList = payload;
  }),
};
