import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { StoreProvider } from 'easy-peasy';

// localization
import './i18n';

// styles
import 'antd/dist/antd.css';
import 'resources/fonts/fonts.css';
import 'resources/styles/index.scss';

import { Routes } from 'constants/routes';
import { MainLoader, ErrorHandler } from 'components';

// easy-peasy store
import { store } from './store';

// Application
import App from './App';

ReactDOM.render(
  <StrictMode>
    <StoreProvider store={store}>
      <BrowserRouter>
        <ErrorHandler errorRoute={Routes.unknownError}>
          <Suspense fallback={<MainLoader className='suspense' isLoading />}>
            <App />
          </Suspense>
        </ErrorHandler>
      </BrowserRouter>
    </StoreProvider>
  </StrictMode>,
  document.getElementById('root'),
);
