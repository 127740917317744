import API from '../API';
import appConfig from 'config/appConfig';
import { IFilterRequestParams, ITradeItem, ITradeItemResponseBody } from 'types';

const PROJECT_API_PREFIX = `${appConfig.serviceUrl}/api/trading`;

const tradingAPI = {
  async getList(params: IFilterRequestParams) {
    const response = await API.get<ITradeItemResponseBody>(`${PROJECT_API_PREFIX}`, {
      params,
    });

    return response.data;
  },

  async createTrade(values: ITradeItem) {
    const payload = {
      projectId: values.projectId,
      type: values.type,
      amount: values.amount,
      tokenPrice: values.price,
      offerId: values.offerId,
      txHash: values.txHash,
    };

    const response = await API.post(`${PROJECT_API_PREFIX}`, payload);
    return response.data;
  },

  async completeTrade(id: number, txHash: string) {
    const response = await API.put(`${PROJECT_API_PREFIX}/${id}/complete`, { txHash });
    return response.data;
  },
};

export default tradingAPI;
