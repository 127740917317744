import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { matchPath, useLocation } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

import { Routes, UserStatus } from 'constants/index';
import { IStoreModel } from 'types';

const useUserApproveRequired = () => {
  const isAuthorized = useStoreState<IStoreModel>((store) => store.auth.isAuthorized);
  const isVerified = useStoreState<IStoreModel, boolean>((store) => !!store.user.profile?.isVerified);
  const status = useStoreState<IStoreModel, UserStatus | undefined>((store) => store.user.profile?.status);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const isWaitingForApprove = isAuthorized && isVerified && status !== UserStatus.Approved;
    const isWaitForApprovePage = matchPath(location.pathname, {
      path: Routes.waitForApprove,
      exact: true,
    });

    if (isWaitingForApprove && !isWaitForApprovePage) {
      history.replace(Routes.waitForApprove);
    }

    if (!isWaitingForApprove && isWaitForApprovePage) {
      history.replace(Routes.home);
    }
  }, [isAuthorized, status, location.pathname, isVerified, history]);
};

export default useUserApproveRequired;
