import { AdminStore } from 'types';
import { projects } from './projects';
import { companies } from './companies';
import { tokensRequests } from './tokens-requests';
import { users } from './users';

export const admin: AdminStore = {
  projects,
  companies,
  tokensRequests,
  users,
};
